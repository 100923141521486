/* DEPARTMENTS */
export const GET_BRANCHES = "GET_BRANCHES"
export const GET_BRANCHES_FAIL = "GET_BRANCHES_FAIL"
export const GET_BRANCHES_SUCCESS = "GET_BRANCHES_SUCCESS"

/* DEPARTMENTS DETAIL*/
export const GET_BRANCH_DETAIL = "GET_BRANCH_DETAIL"
export const GET_BRANCH_DETAIL_FAIL = "GET_BRANCH_DETAIL_FAIL"
export const GET_BRANCH_DETAIL_SUCCESS = "GET_BRANCH_DETAIL_SUCCESS"

export const ADD_NEW_BRANCH = "ADD_NEW_BRANCH"
export const ADD_BRANCH_SUCCESS = "ADD_BRANCH_SUCCESS"
export const ADD_BRANCH_FAIL = "ADD_BRANCH_FAIL"

export const UPDATE_BRANCH = "UPDATE_BRANCH"
export const UPDATE_BRANCH_SUCCESS = "UPDATE_BRANCH_SUCCESS"
export const UPDATE_BRANCH_FAIL = "UPDATE_BRANCH_FAIL"

export const DELETE_BRANCH = "DELETE_BRANCH"
export const DELETE_BRANCH_SUCCESS = "DELETE_BRANCH_SUCCESS"
export const DELETE_BRANCH_FAIL = "DELETE_BRANCH_FAIL"
