import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { useHistory, withRouter } from "react-router-dom"
import { isEmpty } from "lodash"
import { Button, Col, Container, Row } from "reactstrap"

//Import Common Components
import Breadcrumbs from "components/Common/Breadcrumb"
import DeleteModal from "components/Common/DeleteModal"

import { deleteDepartment, getDepartmentDetail } from "store/departments/actions"
import DepartmentDetail from "./departmentDetail"
import TeamMembers from "./teamMembers"
// import OverviewChart from "./overviewChart"
// import AttachedFiles from "./attachedFiles"
// import Comments from "./comments"

//redux
import { useSelector, useDispatch } from "react-redux"

const DepartmentOverview = props => {
  //meta title
  document.title = "Department Overview | React Admin & Dashboard Template"
  const history = useHistory()
  const [deleteModal, setDeleteModal] = useState(false)
  const dispatch = useDispatch()

  const { loading, error, departmentDetail } = useSelector(state => ({
    loading: state.apiGlobal.loading,
    error: state.apiGlobal.error,
    departmentDetail: state.Companies.departmentDetail,
  }))

  const {
    match: { params },
  } = props

  useEffect(() => {
    if (params && params.id) {
      dispatch(getDepartmentDetail(params.id))
    }
  }, [params, getDepartmentDetail])

  useEffect(() => {
    if (!loading && !departmentDetail.id && error.message) {
      history.goBack()
    }
  }, [loading, departmentDetail])

  const handleDeleteEvent = async () => {
    dispatch(deleteDepartment(params.id))
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <DeleteModal
          show={deleteModal}
          onDeleteClick={handleDeleteEvent}
          onCloseClick={() => setDeleteModal(false)}
        />
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Companies" breadcrumbItem="Department Overview" />

          {!isEmpty(departmentDetail) && (
            <>
              <Row>
                <Col lg="8">
                  <DepartmentDetail department={departmentDetail} />
                </Col>

                <Col lg="4">
                  <Button
                    color="danger"
                    className="float-end"
                    onClick={() => setDeleteModal(true)}
                  >
                    Delete
                  </Button>
                  {/* <TeamMembers team={departmentDetail.owner} /> */}
                </Col>
              </Row>

              {/* <Row>
                <Col lg="4">
                  <OverviewChart options={options} series={series} />
                </Col>

                <Col lg="4">
                  <AttachedFiles files={departmentDetail.files} />
                </Col>

                <Col lg="4">
                  <Comments comments={departmentDetail.comments} />
                </Col>
              </Row> */}
            </>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

DepartmentOverview.propTypes = {
  match: PropTypes.object,
}

export default withRouter(DepartmentOverview)
