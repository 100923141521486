export const GET_ATTENDANCE_TYPES = "GET_ATTENDANCE_TYPES"
export const GET_ATTENDANCE_TYPES_FAIL = "GET_ATTENDANCE_TYPES_FAIL"
export const GET_ATTENDANCE_TYPES_SUCCESS = "GET_ATTENDANCE_TYPES_SUCCESS"

export const GET_ATTENDANCE_TYPE_DETAIL = "GET_ATTENDANCE_TYPE_DETAIL"
export const GET_ATTENDANCE_TYPE_DETAIL_FAIL = "GET_ATTENDANCE_TYPE_DETAIL_FAIL"
export const GET_ATTENDANCE_TYPE_DETAIL_SUCCESS =
  "GET_ATTENDANCE_TYPE_DETAIL_SUCCESS"

export const ADD_NEW_ATTENDANCE_TYPE = "ADD_NEW_ATTENDANCE_TYPE"
export const ADD_ATTENDANCE_TYPE_SUCCESS = "ADD_ATTENDANCE_TYPE_SUCCESS"
export const ADD_ATTENDANCE_TYPE_FAIL = "ADD_ATTENDANCE_TYPE_FAIL"

export const UPDATE_ATTENDANCE_TYPE = "UPDATE_ATTENDANCE_TYPE"
export const UPDATE_ATTENDANCE_TYPE_SUCCESS = "UPDATE_ATTENDANCE_TYPE_SUCCESS"
export const UPDATE_ATTENDANCE_TYPE_FAIL = "UPDATE_ATTENDANCE_TYPE_FAIL"

export const DELETE_ATTENDANCE_TYPE = "DELETE_ATTENDANCE_TYPE"
export const DELETE_ATTENDANCE_TYPE_SUCCESS = "DELETE_ATTENDANCE_TYPE_SUCCESS"
export const DELETE_ATTENDANCE_TYPE_FAIL = "DELETE_ATTENDANCE_TYPE_FAIL"
