import React, { useEffect, useState, useRef, useMemo } from "react"
import { withRouter, Link } from "react-router-dom"

import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  UncontrolledTooltip,
  Input,
  Form,
  FormGroup,
} from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
import ReactSelect from "react-select"
import { isEmpty } from "lodash"

//Import Components
import Breadcrumbs from "components/Common/Breadcrumb"
import DeleteModal from "components/Common/DeleteModal"
import TableContainer from "components/Common/TableContainer"
import { PayrollExtraTypes } from "constants/types"

//redux
import { useSelector, useDispatch } from "react-redux"
import {
  getPayrollExtras as onGetPayrollExtras,
  addNewPayrollExtra as onAddNewPayrollExtra,
  updatePayrollExtra as onUpdatePayrollExtra,
  deletePayrollExtra as onDeletePayrollExtra,
} from "store/actions"
import { withTranslation } from "react-i18next"

const PayrollExtrasList = props => {
  //meta title
  document.title = "Payroll Extra | React Admin & Dashboard Template"

  const dispatch = useDispatch()
  const [payrollExtra, setPayrollExtra] = useState()

  const EMPTY_PAYROLL_EXTRA = {
    type: payrollExtra?.type ?? "",
    name: payrollExtra?.name ?? "",
    code: payrollExtra?.code ?? "",
    amount: payrollExtra?.amount ?? 0,
    rules: payrollExtra?.rules ?? [],
  }

  const PAYROLL_EXTRA_VALIDATION = {
    type: Yup.string().required(),
    name: Yup.string().required(),
    code: Yup.string().required(),
    amount: Yup.number().required(),
  }

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: EMPTY_PAYROLL_EXTRA,
    validationSchema: Yup.object(PAYROLL_EXTRA_VALIDATION),
    onSubmit: values => {
      const tmp = {
        id: payrollExtra.id,
        ...values,
      }
      if (isEdit) {
        const updatePayrollExtra = tmp
        // update payrollExtra
        dispatch(onUpdatePayrollExtra(updatePayrollExtra))
        // validation.resetForm()
        // setIsEdit(false)
      } else {
        const newPayrollExtra = tmp
        // save new payrollExtra
        dispatch(onAddNewPayrollExtra(newPayrollExtra))
        // validation.resetForm()
      }
      // toggle()
    },
  })

  const { payrollExtras, loading } = useSelector(state => ({
    payrollExtras: state.PayrollExtras.payrollExtras,
    loading: state.apiGlobal.loading,
  }))

  const [modal, setModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)

  const columns = useMemo(
    () => [
      {
        Header: "#",
        accessor: "id",
        disableFilters: true,
        filterable: true,
      },
      {
        Header: "Name",
        accessor: "name",
        filterable: true,
      },
      {
        Header: "Type",
        accessor: "type",
        filterable: true,
        Cell: cellProps => props.t(cellProps.value),
      },
      {
        Header: "Amount",
        accessor: "amount",
        filterable: true,
      },
      {
        Header: "Action",
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  const payrollExtraData = cellProps.row.original
                  handlePayrollExtraClick(payrollExtraData)
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const payrollExtraData = cellProps.row.original
                  onClickDelete(payrollExtraData)
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </Link>
            </div>
          )
        },
      },
    ],
    [loading]
  )

  useEffect(() => {
    if (payrollExtras && !payrollExtras.length) {
      setIsEdit(false)
    }
  }, [dispatch, payrollExtras])

  useEffect(() => {
    dispatch(onGetPayrollExtras())
  }, [dispatch])

  useEffect(() => {
    setPayrollExtra(payrollExtras)
    setIsEdit(false)
  }, [payrollExtras])

  useEffect(() => {
    if (!isEmpty(payrollExtras) && !!isEdit) {
      setPayrollExtra(payrollExtras)
      setIsEdit(false)
    }
  }, [payrollExtras])

  const toggle = () => {
    setModal(!modal)
  }

  const handlePayrollExtraClick = arg => {
    const payrollExtra = {
      ...arg,
    }

    setPayrollExtra(payrollExtra)
    setIsEdit(true)

    toggle()
  }

  var node = useRef()
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page)
    }
  }

  //delete customer
  const [deleteModal, setDeleteModal] = useState(false)

  const onClickDelete = payrollExtras => {
    setPayrollExtra(payrollExtras)
    setDeleteModal(true)
  }

  const handleDeletePayrollExtra = () => {
    dispatch(onDeletePayrollExtra(payrollExtra.id))
    onPaginationPageChange(1)
    setDeleteModal(false)
  }

  const handleAddPayrollExtraClicks = () => {
    setIsEdit(false)
    toggle()
  }

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeletePayrollExtra}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs
            title="PayrollExtras"
            breadcrumbItem="Payroll Extra List"
          />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={payrollExtras}
                    isGlobalFilter={true}
                    isAddOptions="Payroll Extra"
                    handleAddOptionClick={handleAddPayrollExtraClicks}
                    customPageSize={10}
                    className="custom-header-css"
                  />

                  <Modal isOpen={modal} toggle={toggle} size="lg">
                    <ModalHeader toggle={toggle} tag="h4">
                      {!!isEdit ? "Edit Payroll Extra" : "Add Payroll Extra"}
                    </ModalHeader>
                    <ModalBody>
                      <Form
                        onSubmit={e => {
                          e.preventDefault()
                          validation.handleSubmit()
                          return false
                        }}
                      >
                        <Row>
                          <Col md={12}>
                            <FormGroup>
                              <Label>Type</Label>
                              <ReactSelect
                                isSearchable
                                options={PayrollExtraTypes}
                                getOptionLabel={option => props.t(option.label)}
                                value={PayrollExtraTypes.find(
                                  pe => pe.value === validation.values.type
                                )}
                                onChange={option =>
                                  validation.setFieldValue(
                                    "type",
                                    option?.value
                                  )
                                }
                                placeholder="Select Type"
                                className={
                                  validation.errors.type &&
                                  validation.touched.type
                                    ? " is-invalid form-control p-0 pe-5"
                                    : ""
                                }
                              />
                              <FormFeedback type="invalid">
                                {validation.errors.type}
                              </FormFeedback>
                            </FormGroup>
                          </Col>
                          <Col md={6}>
                            <div className="mb-3">
                              <Label className="form-label">Name</Label>
                              <Input
                                name="name"
                                label="Name"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.name}
                                invalid={
                                  validation.touched.name &&
                                  validation.errors.name
                                    ? true
                                    : false
                                }
                              />
                              <FormFeedback type="invalid">
                                {validation.errors.name}
                              </FormFeedback>
                            </div>
                          </Col>
                          <Col md={6}>
                            <div className="mb-3">
                              <Label className="form-label">Code</Label>
                              <Input
                                name="code"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.code}
                                invalid={
                                  validation.touched.code &&
                                  validation.errors.code
                                    ? true
                                    : false
                                }
                              />
                              <FormFeedback type="invalid">
                                {validation.errors.code}
                              </FormFeedback>
                            </div>
                          </Col>
                          <Col md={12}>
                            <div className="mb-3">
                              <Label className="form-label">Amount</Label>
                              <Input
                                name="amount"
                                type="number"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.amount}
                                invalid={
                                  validation.touched.amount &&
                                  validation.errors.amount
                                    ? true
                                    : false
                                }
                              />
                              <FormFeedback type="invalid">
                                {validation.errors.amount}
                              </FormFeedback>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <div className="text-end">
                              <button
                                type="submit"
                                className="btn btn-success save-payrollExtra"
                              >
                                Save
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </ModalBody>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(withTranslation()(PayrollExtrasList))
