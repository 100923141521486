import {
  GET_PAYROLL_EXTRAS_FAIL,
  GET_PAYROLL_EXTRAS_SUCCESS,
  GET_PAYROLL_EXTRA_DETAIL_FAIL,
  GET_PAYROLL_EXTRA_DETAIL_SUCCESS,
  ADD_PAYROLL_EXTRA_SUCCESS,
  ADD_PAYROLL_EXTRA_FAIL,
  UPDATE_PAYROLL_EXTRA_SUCCESS,
  UPDATE_PAYROLL_EXTRA_FAIL,
  DELETE_PAYROLL_EXTRA_SUCCESS,
  DELETE_PAYROLL_EXTRA_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  payrollExtras: [],
  payrollExtraDetail: {},
  error: {},
}

const PayrollExtras = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_PAYROLL_EXTRAS_SUCCESS:
      return {
        ...state,
        payrollExtras: action.payload,
      }

    case GET_PAYROLL_EXTRAS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_PAYROLL_EXTRA_DETAIL_SUCCESS:
      return {
        ...state,
        payrollExtraDetail: action.payload,
      }

    case GET_PAYROLL_EXTRA_DETAIL_FAIL:
      return {
        ...state,
        payrollExtraDetail: null,
        error: action.payload,
      }

    case ADD_PAYROLL_EXTRA_SUCCESS:
      return {
        ...state,
        payrollExtras: [...state.payrollExtras, action.payload],
      }

    case ADD_PAYROLL_EXTRA_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case UPDATE_PAYROLL_EXTRA_SUCCESS:
      return {
        ...state,
        payrollExtras: state.payrollExtras.map(payrollExtra =>
          payrollExtra.id.toString() === action.payload.id.toString()
            ? { payrollExtra, ...action.payload }
            : payrollExtra
        ),
      }

    case UPDATE_PAYROLL_EXTRA_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_PAYROLL_EXTRA_SUCCESS:
      return {
        ...state,
        payrollExtras: state.payrollExtras.filter(
          payrollExtra =>
            payrollExtra.id.toString() !== action.payload.id.toString()
        ),
      }

    case DELETE_PAYROLL_EXTRA_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default PayrollExtras
