import React, { useEffect, useState, useRef, useMemo } from "react"
import { withRouter, Link } from "react-router-dom"

import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  UncontrolledTooltip,
  Input,
  Form,
  FormGroup,
} from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
import ReactSelect from "react-select"
import ReactSwitch from "react-switch"
import { isEmpty } from "lodash"

//Import Components
import Breadcrumbs from "components/Common/Breadcrumb"
import DeleteModal from "components/Common/DeleteModal"
import TableContainer from "components/Common/TableContainer"
import {
  BalanceEarned,
  Genders,
  MultiLevelApprovals,
  Religions,
} from "constants/types"

//redux
import { useSelector, useDispatch } from "react-redux"
import {
  getLeaveTypes as onGetLeaveTypes,
  addNewLeaveType as onAddNewLeaveType,
  updateLeaveType as onUpdateLeaveType,
  deleteLeaveType as onDeleteLeaveType,
} from "store/actions"

const LeaveTypesList = props => {
  //meta title
  document.title = "Leave Types | React Admin & Dashboard Template"

  const dispatch = useDispatch()
  const [leaveType, setLeaveType] = useState()

  const EMPTY_LEAVE_TYPE = {
    is_active: leaveType?.is_active ?? true,
    name: leaveType?.name ?? "",
    attachments: leaveType?.attachments ?? 0,
    balance: leaveType?.balance ?? 0,
    balance_calculation: leaveType?.balance_calculation ?? 0,
    gender: leaveType?.gender ?? "",
    max_consecutive_days: leaveType?.max_consecutive_days ?? 0,
    multi_level_approval: leaveType?.multi_level_approval ?? false,
    note: leaveType?.note ?? [],
    paid: leaveType?.paid ?? true,
    religion: leaveType?.religion ?? "",
    renew_after_months: leaveType?.renew_after_months ?? 1,
    required_days: leaveType?.required_days ?? 0,
    unlock_after_months: leaveType?.unlock_after_months ?? 0,
    level_approvals: leaveType?.level_approvals ?? [],
  }

  const LEAVE_TYPE_VALIDATION = {
    name: Yup.string().required("Please enter name"),
    attachments: Yup.number().required(),
    balance: Yup.number().required(),
    balance_calculation: Yup.number().required(),
    gender: Yup.string(),
    max_consecutive_days: Yup.number().required(),
    multi_level_approval: Yup.bool().required(),
    note: Yup.object().nullable(),
    paid: Yup.bool().required(),
    religion: Yup.string(),
    renew_after_months: Yup.number().required(),
    required_days: Yup.number().required(),
    unlock_after_months: Yup.number().required(),
  }

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: EMPTY_LEAVE_TYPE,
    validationSchema: Yup.object(LEAVE_TYPE_VALIDATION),
    onSubmit: values => {
      const tmp = {
        id: leaveType.id,
        ...values,
      }
      if (isEdit) {
        const updateLeaveType = tmp
        // update leaveType
        dispatch(onUpdateLeaveType(updateLeaveType))
        // validation.resetForm()
        // setIsEdit(false)
      } else {
        const newLeaveType = tmp
        // save new leaveType
        dispatch(onAddNewLeaveType(newLeaveType))
        // validation.resetForm()
      }
      // toggle()
    },
  })

  const { leaveTypes, loading } = useSelector(state => ({
    leaveTypes: state.LeaveTypes.leaveTypes,
    loading: state.apiGlobal.loading,
  }))

  const [modal, setModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)

  const columns = useMemo(
    () => [
      {
        Header: "#",
        accessor: "id",
        disableFilters: true,
        filterable: true,
      },
      {
        Header: "Name",
        accessor: "name",
        filterable: true,
      },
      {
        Header: "Status",
        accessor: "is_active",
        filterable: true,
        Cell: cellProps =>
          cellProps.value !== undefined && (
            <ReactSwitch
              disabled={loading}
              onChange={() => {
                dispatch(
                  onUpdateLeaveType({
                    ...cellProps.row.original,
                    is_active: !cellProps.value,
                  })
                )
              }}
              checked={cellProps.value ?? false}
            />
          ),
      },
      {
        Header: "Action",
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  const leaveTypeData = cellProps.row.original
                  handleLeaveTypeClick(leaveTypeData)
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const leaveTypeData = cellProps.row.original
                  onClickDelete(leaveTypeData)
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </Link>
            </div>
          )
        },
      },
    ],
    [loading]
  )

  useEffect(() => {
    if (leaveTypes && !leaveTypes.length) {
      setIsEdit(false)
    }
  }, [dispatch, leaveTypes])

  useEffect(() => {
    dispatch(onGetLeaveTypes())
  }, [dispatch])

  useEffect(() => {
    setLeaveType(leaveTypes)
    setIsEdit(false)
  }, [leaveTypes])

  useEffect(() => {
    if (!isEmpty(leaveTypes) && !!isEdit) {
      setLeaveType(leaveTypes)
      setIsEdit(false)
    }
  }, [leaveTypes])

  const toggle = () => {
    setModal(!modal)
  }

  const handleLeaveTypeClick = arg => {
    const leaveType = {
      ...arg,
    }

    setLeaveType(leaveType)
    setIsEdit(true)

    toggle()
  }

  var node = useRef()
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page)
    }
  }

  //delete customer
  const [deleteModal, setDeleteModal] = useState(false)

  const onClickDelete = leaveTypes => {
    setLeaveType(leaveTypes)
    setDeleteModal(true)
  }

  const handleDeleteLeaveType = () => {
    dispatch(onDeleteLeaveType(leaveType.id))
    onPaginationPageChange(1)
    setDeleteModal(false)
  }

  const handleAddLeaveTypeClicks = () => {
    setIsEdit(false)
    toggle()
  }

  const keyField = "id"

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteLeaveType}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="LeaveTypes" breadcrumbItem="Leave Type List" />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={leaveTypes}
                    isGlobalFilter={true}
                    isAddOptions="Leave Type"
                    handleAddOptionClick={handleAddLeaveTypeClicks}
                    customPageSize={10}
                    className="custom-header-css"
                  />

                  <Modal isOpen={modal} toggle={toggle} size="lg">
                    <ModalHeader toggle={toggle} tag="h4">
                      {!!isEdit ? "Edit LeaveType" : "Add LeaveType"}
                    </ModalHeader>
                    <ModalBody>
                      <Form
                        onSubmit={e => {
                          e.preventDefault()
                          validation.handleSubmit()
                          return false
                        }}
                      >
                        <Row>
                          <Col md={12}>
                            <div className="mb-3">
                              <Label className="form-label">Name</Label>
                              <Input
                                name="name"
                                label="Name"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.name}
                                invalid={
                                  validation.touched.name &&
                                  validation.errors.name
                                    ? true
                                    : false
                                }
                              />
                              <FormFeedback type="invalid">
                                {validation.errors.name}
                              </FormFeedback>
                            </div>
                          </Col>
                          <Col md={6}>
                            <div className="mb-3">
                              <Label className="form-label">
                                Unlock after (months)
                              </Label>
                              <Input
                                name="unlock_after_months"
                                type="number"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.unlock_after_months}
                                invalid={
                                  validation.touched.unlock_after_months &&
                                  validation.errors.unlock_after_months
                                    ? true
                                    : false
                                }
                              />
                              <FormFeedback type="invalid">
                                {validation.errors.unlock_after_months}
                              </FormFeedback>
                            </div>
                          </Col>
                          <Col md={6}>
                            <div className="mb-3">
                              <Label className="form-label">
                                Required days before submitting
                              </Label>
                              <Input
                                name="required_days"
                                type="number"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.required_days}
                                invalid={
                                  validation.touched.required_days &&
                                  validation.errors.required_days
                                    ? true
                                    : false
                                }
                              />
                              <FormFeedback type="invalid">
                                {validation.errors.required_days}
                              </FormFeedback>
                            </div>
                          </Col>
                          <Col md={6}>
                            <Label className="form-label">Paid</Label>
                            <FormGroup>
                              <ReactSwitch
                                onChange={() => {
                                  validation.setFieldValue(
                                    "paid",
                                    !validation.values.paid
                                  )
                                }}
                                checked={validation.values.paid}
                              />
                            </FormGroup>
                          </Col>
                          <Col md={6}>
                            <Label className="form-label">Unlimited</Label>
                            <FormGroup>
                              <ReactSwitch
                                onChange={() => {
                                  validation.setFieldValue(
                                    "balance",
                                    validation.values.balance > 0 ? 0 : 1
                                  )
                                }}
                                checked={validation.values.balance === 0}
                              />
                            </FormGroup>
                          </Col>
                          <Col md={6}>
                            <Label className="form-label">
                              Multi-level approval
                            </Label>
                            <FormGroup>
                              <ReactSwitch
                                onChange={() => {
                                  validation.setFieldValue(
                                    "multi_level_approval",
                                    !validation.values.multi_level_approval
                                  )
                                }}
                                checked={validation.values.multi_level_approval}
                              />
                            </FormGroup>
                          </Col>
                          <Col md={6}>
                            <Label className="form-label">
                              Require attachments
                            </Label>
                            <FormGroup>
                              <ReactSwitch
                                onChange={() => {
                                  validation.setFieldValue(
                                    "attachments",
                                    validation.values.attachments > 0 ? 0 : 1
                                  )
                                }}
                                checked={validation.values.attachments > 0}
                              />
                            </FormGroup>
                          </Col>
                          <Col md={6}>
                            <Label className="form-label">Religion based</Label>
                            <FormGroup>
                              <ReactSwitch
                                onChange={() => {
                                  validation.setFieldValue(
                                    "religion",
                                    validation.values.religion
                                      ? undefined
                                      : "muslim"
                                  )
                                }}
                                checked={!isEmpty(validation.values.religion)}
                              />
                            </FormGroup>
                          </Col>
                          <Col md={6}>
                            <Label className="form-label">Gender based</Label>
                            <FormGroup>
                              <ReactSwitch
                                onChange={() => {
                                  validation.setFieldValue(
                                    "gender",
                                    validation.values.gender
                                      ? undefined
                                      : "male"
                                  )
                                }}
                                checked={!isEmpty(validation.values.gender)}
                              />
                            </FormGroup>
                          </Col>
                          {validation.values.attachments > 0 && (
                            <Col md={6}>
                              <FormGroup>
                                <Label className="form-label">
                                  Require attachments count
                                </Label>
                                <Input
                                  name="attachments"
                                  type="number"
                                  min="1"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.attachments}
                                  invalid={
                                    validation.touched.attachments &&
                                    validation.errors.attachments
                                      ? true
                                      : false
                                  }
                                />
                                <FormFeedback type="invalid">
                                  {validation.errors.attachments}
                                </FormFeedback>
                              </FormGroup>
                            </Col>
                          )}
                          {validation.values.religion && (
                            <Col md={6}>
                              <FormGroup>
                                <Label className="form-label">Religion</Label>
                                <ReactSelect
                                  isSearchable
                                  options={Religions}
                                  value={Religions.find(
                                    ct =>
                                      ct.value === validation.values.religion
                                  )}
                                  onChange={option =>
                                    validation.setFieldValue(
                                      "religion",
                                      option?.value
                                    )
                                  }
                                  placeholder="Select Type"
                                  className={
                                    validation.errors.religion &&
                                    validation.touched.religion
                                      ? " is-invalid form-control p-0 pe-5"
                                      : ""
                                  }
                                />
                                <FormFeedback type="invalid">
                                  {validation.errors.religion}
                                </FormFeedback>
                              </FormGroup>
                            </Col>
                          )}
                          {validation.values.gender && (
                            <Col md={6}>
                              <FormGroup>
                                <Label className="form-label">Gender</Label>
                                <ReactSelect
                                  isSearchable
                                  options={Genders}
                                  value={Genders.find(
                                    ct => ct.value === validation.values.gender
                                  )}
                                  onChange={option =>
                                    validation.setFieldValue(
                                      "gender",
                                      option?.value
                                    )
                                  }
                                  placeholder="Select Type"
                                  className={
                                    validation.errors.gender &&
                                    validation.touched.gender
                                      ? " is-invalid form-control p-0 pe-5"
                                      : ""
                                  }
                                />
                                <FormFeedback type="invalid">
                                  {validation.errors.gender}
                                </FormFeedback>
                              </FormGroup>
                            </Col>
                          )}
                          {validation.values.balance > 0 && (
                            <>
                              <Col md={6}>
                                <FormGroup>
                                  <Label className="form-label">Balance</Label>
                                  <Input
                                    name="balance"
                                    type="number"
                                    min="1"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.balance}
                                    invalid={
                                      validation.touched.balance &&
                                      validation.errors.balance
                                        ? true
                                        : false
                                    }
                                  />
                                  <FormFeedback type="invalid">
                                    {validation.errors.balance}
                                  </FormFeedback>
                                </FormGroup>
                              </Col>
                              <Col md={6}>
                                <FormGroup>
                                  <Label className="form-label">
                                    Renew after(months)
                                  </Label>
                                  <Input
                                    name="renew_after_months"
                                    type="number"
                                    min="1"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.renew_after_months}
                                    invalid={
                                      validation.touched.renew_after_months &&
                                      validation.errors.renew_after_months
                                        ? true
                                        : false
                                    }
                                  />
                                  <FormFeedback type="invalid">
                                    {validation.errors.renew_after_months}
                                  </FormFeedback>
                                </FormGroup>
                              </Col>
                              <Col md={6}>
                                <FormGroup>
                                  <Label className="form-label">
                                    How is balance earned?
                                  </Label>
                                  <ReactSelect
                                    isSearchable
                                    options={BalanceEarned}
                                    value={BalanceEarned.find(
                                      ct =>
                                        ct.value ===
                                        validation.values.balance_calculation
                                    )}
                                    onChange={option =>
                                      validation.setFieldValue(
                                        "balance_calculation",
                                        option?.value
                                      )
                                    }
                                    placeholder="Select Type"
                                    className={
                                      validation.errors.balance_calculation &&
                                      validation.touched.balance_calculation
                                        ? " is-invalid form-control p-0 pe-5"
                                        : ""
                                    }
                                  />
                                  <FormFeedback type="invalid">
                                    {validation.errors.balance_calculation}
                                  </FormFeedback>
                                </FormGroup>
                              </Col>
                            </>
                          )}
                          {validation.values.multi_level_approval && (
                            <>
                              <Col md={6}>
                                <FormGroup>
                                  <Label className="form-label">
                                    Level One
                                  </Label>
                                  <ReactSelect
                                    isSearchable
                                    options={MultiLevelApprovals}
                                    value={MultiLevelApprovals.find(
                                      mla =>
                                        mla.value ===
                                        validation.values.level_approvals[0]
                                          ?.value
                                    )}
                                    onChange={option => {
                                      validation.setFieldValue(
                                        "level_approvals",
                                        [{ ...option, level: 1 }]
                                      )
                                    }}
                                    placeholder="Select Type"
                                    className={
                                      validation.errors.level_approvals &&
                                      validation.touched.level_approvals
                                        ? " is-invalid form-control p-0 pe-5"
                                        : ""
                                    }
                                  />
                                  <FormFeedback type="invalid">
                                    {validation.errors.level_approvals}
                                  </FormFeedback>
                                </FormGroup>
                                <FormGroup>
                                  <Label className="form-label">
                                    Level Two
                                  </Label>
                                  <ReactSelect
                                    isSearchable
                                    options={MultiLevelApprovals.filter(
                                      ({ value: id1 }) =>
                                        !validation.values.level_approvals.some(
                                          ({ value: id2 }) => id2 === id1
                                        )
                                    )}
                                    value={MultiLevelApprovals.find(
                                      mla =>
                                        mla.value ===
                                        validation.values.level_approvals[1]
                                          ?.value
                                    )}
                                    onChange={option => {
                                      let tmp = [
                                        ...validation.values.level_approvals,
                                      ]
                                      tmp[1] = {
                                        ...option,
                                        level: 2,
                                      }
                                      validation.setFieldValue(
                                        "level_approvals",
                                        tmp
                                      )
                                    }}
                                    placeholder="Select Type"
                                    className={
                                      validation.errors.level_approvals &&
                                      validation.touched.level_approvals
                                        ? " is-invalid form-control p-0 pe-5"
                                        : ""
                                    }
                                  />
                                  <FormFeedback type="invalid">
                                    {validation.errors.level_approvals}
                                  </FormFeedback>
                                </FormGroup>
                              </Col>
                            </>
                          )}
                        </Row>
                        <Row>
                          <Col>
                            <div className="text-end">
                              <button
                                type="submit"
                                className="btn btn-success save-leaveType"
                              >
                                Save
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </ModalBody>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(LeaveTypesList)
