/* DEPARTMENTS */
export const GET_JOB_TITLES = "GET_JOB_TITLES"
export const GET_JOB_TITLES_FAIL = "GET_JOB_TITLES_FAIL"
export const GET_JOB_TITLES_SUCCESS = "GET_JOB_TITLES_SUCCESS"

/* DEPARTMENTS DETAIL*/
export const GET_JOB_TITLE_DETAIL = "GET_JOB_TITLE_DETAIL"
export const GET_JOB_TITLE_DETAIL_FAIL = "GET_JOB_TITLE_DETAIL_FAIL"
export const GET_JOB_TITLE_DETAIL_SUCCESS = "GET_JOB_TITLE_DETAIL_SUCCESS"

export const ADD_NEW_JOB_TITLE = "ADD_NEW_JOB_TITLE"
export const ADD_JOB_TITLE_SUCCESS = "ADD_JOB_TITLE_SUCCESS"
export const ADD_JOB_TITLE_FAIL = "ADD_JOB_TITLE_FAIL"

export const UPDATE_JOB_TITLE = "UPDATE_JOB_TITLE"
export const UPDATE_JOB_TITLE_SUCCESS = "UPDATE_JOB_TITLE_SUCCESS"
export const UPDATE_JOB_TITLE_FAIL = "UPDATE_JOB_TITLE_FAIL"

export const DELETE_JOB_TITLE = "DELETE_JOB_TITLE"
export const DELETE_JOB_TITLE_SUCCESS = "DELETE_JOB_TITLE_SUCCESS"
export const DELETE_JOB_TITLE_FAIL = "DELETE_JOB_TITLE_FAIL"
