import React, { useEffect, useState, useRef, useMemo } from "react"
import { withRouter, Link } from "react-router-dom"

import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  UncontrolledTooltip,
  Input,
  Form,
} from "reactstrap"
import ReactSelect from "react-select"
import * as Yup from "yup"
import { useFormik } from "formik"
import { find, get, isEmpty } from "lodash"

//Import Common Components
import Breadcrumbs from "components/Common/Breadcrumb"
import DeleteModal from "components/Common/DeleteModal"
import TableContainer from "components/Common/TableContainer"

//redux
import { useSelector, useDispatch } from "react-redux"

import {
  addNewDepartment,
  getBranches,
  getDepartments,
  getJobTitles,
  getUsers as onGetUsers,
  updateDepartment,
} from "store/actions"

const DepartmentsList = props => {
  //meta title
  document.title = "Departments | React Admin & Dashboard Template"

  const dispatch = useDispatch()
  const [department, setDepartment] = useState()

  const EMPTY_DEPARTMENT = {
    name: department?.name ?? "",
    employee_id: department?.employee_id ?? undefined,
  }

  const DEPARTMENT_VALIDATION = {
    name: Yup.string().required("Please Enter user name"),
  }

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: EMPTY_DEPARTMENT,
    validationSchema: Yup.object(DEPARTMENT_VALIDATION),
    onSubmit: values => {
      const tmp = {
        id: department.id,
        ...values,
      }
      if (isEdit) {
        // update department
        dispatch(updateDepartment(tmp))
        // validation.resetForm()
        // setIsEdit(false)
      } else {
        // save new department
        dispatch(addNewDepartment(tmp))
        // validation.resetForm()
      }
      // toggle()
    },
  })

  const { users, departments } = useSelector(state => ({
    users: state.users.users,
    departments: state.Departments.departments,
  }))

  const [modal, setModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)

  const columns = useMemo(
    () => [
      {
        Header: "id",
        accessor: "id",
      },
      {
        Header: "Name",
        accessor: "name",
        filterable: true,
      },
      {
        Header: "Manager",
        accessor: "employee_id",
        filterable: true,
        Cell: cellProps => {
          const profile = get(
            find(users, { id: cellProps.value }),
            "user.profile"
          )
          if (profile) {
            return `${profile.first_name} ${profile.last_name}`
          }
        },
      },
      {
        Header: "Action",
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  const rowData = cellProps.row.original
                  handleRowClick(rowData)
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const rowData = cellProps.row.original
                  onClickDelete(rowData)
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </Link>
            </div>
          )
        },
      },
    ],
    [users]
  )

  useEffect(() => {
    if (users && !users.length) {
      setIsEdit(false)
    }
  }, [dispatch, users])

  useEffect(() => {
    dispatch(onGetUsers())
    dispatch(getDepartments())
  }, [dispatch])

  useEffect(() => {
    setDepartment(users)
    setIsEdit(false)
  }, [users])

  useEffect(() => {
    if (!isEmpty(users) && !!isEdit) {
      setDepartment(users)
      setIsEdit(false)
    }
  }, [users])

  const toggle = () => {
    setModal(!modal)
  }

  const handleRowClick = arg => {
    const department = {
      ...arg,
    }

    setDepartment(department)
    setIsEdit(true)

    toggle()
  }

  var node = useRef()
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page)
    }
  }

  //delete customer
  const [deleteModal, setDeleteModal] = useState(false)

  const onClickDelete = users => {
    setDepartment(users)
    setDeleteModal(true)
  }

  const handleDeleteUser = () => {
    dispatch(onDeleteUser(department.id))
    onPaginationPageChange(1)
    setDeleteModal(false)
  }

  const handleAddClick = () => {
    setIsEdit(false)
    toggle()
  }

  const keyField = "id"

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Departments" breadcrumbItem="Departments List" />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={departments}
                    isGlobalFilter={true}
                    isAddOptions="Department"
                    handleAddOptionClick={handleAddClick}
                    customPageSize={10}
                    className="custom-header-css"
                  />

                  <Modal isOpen={modal} toggle={toggle}>
                    <ModalHeader toggle={toggle} tag="h4">
                      {!!isEdit ? "Edit Department" : "Add Department"}
                    </ModalHeader>
                    <ModalBody>
                      <Form
                        onSubmit={e => {
                          e.preventDefault()
                          validation.handleSubmit()
                          return false
                        }}
                      >
                        <Row>
                          <Col xs={6}>
                            <div className="mb-3">
                              <Label className="form-label">Name</Label>
                              <Input
                                name="name"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.name || ""}
                                invalid={
                                  validation.touched.name &&
                                  validation.errors.name
                                    ? true
                                    : false
                                }
                              />
                              <FormFeedback type="invalid">
                                {validation.errors.name}
                              </FormFeedback>
                            </div>
                          </Col>
                          <Col md={6}>
                            <div className="mb-3">
                              <Label className="form-label">Manager</Label>
                              <ReactSelect
                                isSearchable
                                options={users}
                                value={users.find(
                                  branch =>
                                    branch.id === validation.values.employee_id
                                )}
                                getOptionLabel={option =>
                                  `${option.user?.profile?.first_name} ${option.user?.profile?.last_name} (${option.user?.name})`
                                }
                                getOptionValue={option => option.id}
                                onChange={option =>
                                  validation.setFieldValue(
                                    "employee_id",
                                    option?.id
                                  )
                                }
                                placeholder="Select an Employee"
                                className={
                                  validation.errors.employee_id &&
                                  validation.touched.employee_id
                                    ? " is-invalid form-control p-0 pe-5"
                                    : ""
                                }
                              />
                              <FormFeedback type="invalid">
                                {validation.errors.employee_id}
                              </FormFeedback>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <div className="text-end">
                              <button
                                type="submit"
                                className="btn btn-success save-user"
                              >
                                Save
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </ModalBody>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(DepartmentsList)
