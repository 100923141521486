import {
  GET_ATTENDANCE_TYPES,
  GET_ATTENDANCE_TYPES_FAIL,
  GET_ATTENDANCE_TYPES_SUCCESS,
  GET_ATTENDANCE_TYPE_DETAIL,
  GET_ATTENDANCE_TYPE_DETAIL_FAIL,
  GET_ATTENDANCE_TYPE_DETAIL_SUCCESS,
  ADD_NEW_ATTENDANCE_TYPE,
  ADD_ATTENDANCE_TYPE_SUCCESS,
  ADD_ATTENDANCE_TYPE_FAIL,
  UPDATE_ATTENDANCE_TYPE,
  UPDATE_ATTENDANCE_TYPE_SUCCESS,
  UPDATE_ATTENDANCE_TYPE_FAIL,
  DELETE_ATTENDANCE_TYPE,
  DELETE_ATTENDANCE_TYPE_SUCCESS,
  DELETE_ATTENDANCE_TYPE_FAIL,
} from "./actionTypes"

export const getAttendanceTypes = () => ({
  type: GET_ATTENDANCE_TYPES,
})

export const getAttendanceTypesSuccess = attendanceTypes => ({
  type: GET_ATTENDANCE_TYPES_SUCCESS,
  payload: attendanceTypes,
})

export const getAttendanceTypesFail = error => ({
  type: GET_ATTENDANCE_TYPES_FAIL,
  payload: error,
})

export const getAttendanceTypeDetail = attendanceTypeId => ({
  type: GET_ATTENDANCE_TYPE_DETAIL,
  attendanceTypeId,
})

export const getAttendanceTypeDetailSuccess = attendanceTypeDetails => ({
  type: GET_ATTENDANCE_TYPE_DETAIL_SUCCESS,
  payload: attendanceTypeDetails,
})

export const getAttendanceTypeDetailFail = error => ({
  type: GET_ATTENDANCE_TYPE_DETAIL_FAIL,
  payload: error,
})

export const addNewAttendanceType = attendanceType => ({
  type: ADD_NEW_ATTENDANCE_TYPE,
  payload: attendanceType,
})

export const addAttendanceTypeSuccess = attendanceType => ({
  type: ADD_ATTENDANCE_TYPE_SUCCESS,
  payload: attendanceType,
})

export const addAttendanceTypeFail = error => ({
  type: ADD_ATTENDANCE_TYPE_FAIL,
  payload: error,
})

export const updateAttendanceType = attendanceType => ({
  type: UPDATE_ATTENDANCE_TYPE,
  payload: attendanceType,
})

export const updateAttendanceTypeSuccess = attendanceType => ({
  type: UPDATE_ATTENDANCE_TYPE_SUCCESS,
  payload: attendanceType,
})

export const updateAttendanceTypeFail = error => ({
  type: UPDATE_ATTENDANCE_TYPE_FAIL,
  payload: error,
})

export const deleteAttendanceType = attendanceType => ({
  type: DELETE_ATTENDANCE_TYPE,
  payload: attendanceType,
})

export const deleteAttendanceTypeSuccess = attendanceType => ({
  type: DELETE_ATTENDANCE_TYPE_SUCCESS,
  payload: attendanceType,
})

export const deleteAttendanceTypeFail = error => ({
  type: DELETE_ATTENDANCE_TYPE_FAIL,
  payload: error,
})
