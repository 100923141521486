import { del, get, post } from "../api_helper"
import * as url from "../url_helper"

const URI = url.PAYROLL_EXTRAS_URI

// get all entities
const all = () => get(URI)

// get single entity
const show = id => get(`${URI}/${id}`)

// add an entity
const create = data => post(URI, data)

// update an entity
const update = data => post(`${URI}/${data.id}`, data)

// delete an entity
const destroy = (id, force = true) => del(`${URI}/${id}?force=${force ? 1 : 0}`)

export const PayrollExtraApi = {
  all,
  show,
  create,
  update,
  destroy,
}
