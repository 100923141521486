import { call, put, takeEvery } from "redux-saga/effects"

// WorkPlan Redux States
import {
  GET_WORK_PLANS,
  GET_WORK_PLAN_DETAIL,
  ADD_NEW_WORK_PLAN,
  DELETE_WORK_PLAN,
  UPDATE_WORK_PLAN,
} from "./actionTypes"
import {
  getWorkPlansSuccess,
  getWorkPlansFail,
  getWorkPlanDetailSuccess,
  getWorkPlanDetailFail,
  addWorkPlanFail,
  addWorkPlanSuccess,
  updateWorkPlanSuccess,
  updateWorkPlanFail,
  deleteWorkPlanSuccess,
  deleteWorkPlanFail,
} from "./actions"

//Include Both Helper File with needed methods
import { WorkPlanApi } from "helpers/Api/work-plans"
import { apiError, apiLoaded, apiLoading } from "store/actions"

function* fetchWorkPlans() {
  try {
    yield put(apiLoading())
    const response = yield call(WorkPlanApi.all)
    if (response.success) {
      yield put(apiLoaded())
      yield put(getWorkPlansSuccess(response.data))
    } else {
      yield put(apiError(response.message))
      yield put(getWorkPlansFail(response.message))
    }
  } catch (error) {
    yield put(apiError(error.response?.data))
    yield put(getWorkPlansFail(error))
  }
}

function* fetchWorkPlanDetail({ workPlanId }) {
  try {
    yield put(apiLoading())
    if (workPlanId === undefined) {
      yield put(getWorkPlanDetailSuccess(null))
    } else {
      const response = yield call(WorkPlanApi.show, workPlanId)
      if (response.success) {
        yield put(apiLoaded())
        yield put(getWorkPlanDetailSuccess(response.data))
      } else {
        yield put(apiError(response.message))
        yield put(getWorkPlanDetailFail(response.message))
      }
    }
  } catch (error) {
    yield put(apiError(error.response?.data))
    yield put(getWorkPlanDetailFail(error))
  }
}

function* onUpdateWorkPlan({ payload: workPlan }) {
  try {
    yield put(apiLoading())
    const response = yield call(WorkPlanApi.update, workPlan)
    if (response.success) {
      yield put(apiLoaded())
      yield put(updateWorkPlanSuccess(response.data))
    } else {
      yield put(apiError(response.message))
      yield put(updateWorkPlanFail(response.message))
    }
  } catch (error) {
    if (error.response) {
      yield put(apiError(error.response.message))
      yield put(updateWorkPlanFail(error.response.data))
    } else {
      yield put(updateWorkPlanFail(error))
      yield put(apiError(error.response?.data))
    }
  }
}

function* onDeleteWorkPlan({ payload: workPlan }) {
  try {
    yield put(apiLoading())
    const response = yield call(WorkPlanApi.destroy, workPlan)
    if (response.success) {
      yield put(apiLoaded())
      yield put(deleteWorkPlanSuccess(response.data))
    } else {
      yield put(apiError(response.message))
      yield put(deleteWorkPlanFail(response.message))
    }
  } catch (error) {
    yield put(apiError(error.response?.data))
    yield put(deleteWorkPlanFail(error))
  }
}

function* onAddNewWorkPlan({ payload: workPlan }) {
  try {
    yield put(apiLoading())
    const response = yield call(WorkPlanApi.create, workPlan)
    if (response.success) {
      yield put(apiLoaded())
      yield put(addWorkPlanSuccess(response.data))
    } else {
      yield put(apiError(response.message))
      yield put(addWorkPlanFail(response.message))
    }
  } catch (error) {
    if (error.response) {
      yield put(addWorkPlanFail(error.response.data))
      yield put(apiError(error.response.message))
    } else {
      yield put(addWorkPlanFail(error))
      yield put(apiError(error.response?.data))
    }
  }
}

function* workPlansSaga() {
  yield takeEvery(GET_WORK_PLANS, fetchWorkPlans)
  yield takeEvery(GET_WORK_PLAN_DETAIL, fetchWorkPlanDetail)
  yield takeEvery(ADD_NEW_WORK_PLAN, onAddNewWorkPlan)
  yield takeEvery(UPDATE_WORK_PLAN, onUpdateWorkPlan)
  yield takeEvery(DELETE_WORK_PLAN, onDeleteWorkPlan)
}

export default workPlansSaga
