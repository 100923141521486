import {
  GET_WORK_PLANS_FAIL,
  GET_WORK_PLANS_SUCCESS,
  GET_WORK_PLAN_DETAIL_FAIL,
  GET_WORK_PLAN_DETAIL_SUCCESS,
  ADD_WORK_PLAN_SUCCESS,
  ADD_WORK_PLAN_FAIL,
  UPDATE_WORK_PLAN_SUCCESS,
  UPDATE_WORK_PLAN_FAIL,
  DELETE_WORK_PLAN_SUCCESS,
  DELETE_WORK_PLAN_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  workPlans: [],
  workPlanDetail: {},
  error: {},
}

const WorkPlans = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_WORK_PLANS_SUCCESS:
      return {
        ...state,
        error: {},
        workPlans: action.payload,
      }

    case GET_WORK_PLANS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_WORK_PLAN_DETAIL_SUCCESS:
      return {
        ...state,
        error: {},
        workPlanDetail: action.payload,
      }

    case GET_WORK_PLAN_DETAIL_FAIL:
      return {
        ...state,
        workPlanDetail: {},
        error: action.payload,
      }

    case ADD_WORK_PLAN_SUCCESS:
      return {
        ...state,
        error: {},
        workPlanDetail: action.payload,
        workPlans: [...state.workPlans, action.payload],
      }

    case ADD_WORK_PLAN_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case UPDATE_WORK_PLAN_SUCCESS:
      return {
        ...state,
        error: {},
        workPlanDetail: action.payload,
        workPlans: state.workPlans.map(workPlan =>
          workPlan.id.toString() === action.payload.id.toString()
            ? { workPlan, ...action.payload }
            : workPlan
        ),
      }

    case UPDATE_WORK_PLAN_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_WORK_PLAN_SUCCESS:
      return {
        ...state,
        error: {},
        workPlans: state.workPlans.filter(
          workPlan => workPlan.id.toString() !== action.payload.id.toString()
        ),
      }

    case DELETE_WORK_PLAN_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default WorkPlans
