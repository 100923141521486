import {
  GET_ATTENDANCE_TYPES_FAIL,
  GET_ATTENDANCE_TYPES_SUCCESS,
  GET_ATTENDANCE_TYPE_DETAIL_FAIL,
  GET_ATTENDANCE_TYPE_DETAIL_SUCCESS,
  ADD_ATTENDANCE_TYPE_SUCCESS,
  ADD_ATTENDANCE_TYPE_FAIL,
  UPDATE_ATTENDANCE_TYPE_SUCCESS,
  UPDATE_ATTENDANCE_TYPE_FAIL,
  DELETE_ATTENDANCE_TYPE_SUCCESS,
  DELETE_ATTENDANCE_TYPE_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  attendanceTypes: [],
  attendanceTypeDetail: {},
  error: {},
}

const AttendanceTypes = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ATTENDANCE_TYPES_SUCCESS:
      return {
        ...state,
        attendanceTypes: action.payload,
      }

    case GET_ATTENDANCE_TYPES_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_ATTENDANCE_TYPE_DETAIL_SUCCESS:
      return {
        ...state,
        attendanceTypeDetail: action.payload,
      }

    case GET_ATTENDANCE_TYPE_DETAIL_FAIL:
      return {
        ...state,
        attendanceTypeDetail: null,
        error: action.payload,
      }

    case ADD_ATTENDANCE_TYPE_SUCCESS:
      return {
        ...state,
        attendanceTypes: [...state.attendanceTypes, action.payload],
      }

    case ADD_ATTENDANCE_TYPE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case UPDATE_ATTENDANCE_TYPE_SUCCESS:
      return {
        ...state,
        attendanceTypes: state.attendanceTypes.map(attendanceType =>
          attendanceType.id.toString() === action.payload.id.toString()
            ? { attendanceType, ...action.payload }
            : attendanceType
        ),
      }

    case UPDATE_ATTENDANCE_TYPE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_ATTENDANCE_TYPE_SUCCESS:
      return {
        ...state,
        attendanceTypes: state.attendanceTypes.filter(
          attendanceType =>
            attendanceType.id.toString() !== action.payload.id.toString()
        ),
      }

    case DELETE_ATTENDANCE_TYPE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default AttendanceTypes
