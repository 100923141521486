import React, { useEffect, useState, useRef, useMemo } from "react"
import { withRouter, Link } from "react-router-dom"

import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  UncontrolledTooltip,
  Input,
  Form,
} from "reactstrap"

import * as Yup from "yup"
import { useFormik } from "formik"

//Import Common Components
import Breadcrumbs from "components/Common/Breadcrumb"
import DeleteModal from "components/Common/DeleteModal"
import TableContainer from "components/Common/TableContainer"

//redux
import { useSelector, useDispatch } from "react-redux"

import { addNewBranch, getBranches, updateBranch } from "store/actions"
import ReactSwitch from "react-switch"

const BranchesList = props => {
  //meta title
  document.title = "Branches | React Admin & Dashboard Template"

  const dispatch = useDispatch()
  const [branch, setBranch] = useState()

  const EMPTY_BRANCH = {
    name: branch?.name ?? "",
    active: branch?.active ?? true,
    address: branch?.address ?? "",
    gps_latitude: branch?.gps_latitude ?? "",
    gps_longitude: branch?.gps_longitude ?? "",
    gps_radius: branch?.gps_radius ?? 0,
  }

  const BRANCH_VALIDATION = {
    name: Yup.string().required("Please enter name"),
    active: Yup.boolean().required(),
    address: Yup.string().required(),
    gps_latitude: Yup.string().required(),
    gps_longitude: Yup.string().required(),
    gps_radius: Yup.string().required(),
  }

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: EMPTY_BRANCH,
    validationSchema: Yup.object(BRANCH_VALIDATION),
    onSubmit: values => {
      const tmp = {
        id: branch?.id,
        ...values,
      }
      if (isEdit) {
        // update branch
        dispatch(updateBranch(tmp))
        // validation.resetForm()
        // setIsEdit(false)
      } else {
        // save new branch
        dispatch(addNewBranch(tmp))
        // validation.resetForm()
      }
      // toggle()
    },
  })

  const { branches } = useSelector(state => ({
    branches: state.Branches.branches,
  }))

  const [modal, setModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)

  const columns = useMemo(
    () => [
      {
        Header: "id",
        accessor: "id",
      },
      {
        Header: "Name",
        accessor: "name",
        filterable: true,
      },
      {
        Header: "Active",
        accessor: "location.active",
        filterable: true,
        Cell: cellProps =>
          cellProps.value !== undefined && (
            <ReactSwitch
              onChange={() => {
                dispatch(
                  updateBranch({
                    ...cellProps.row.original,
                    active: !cellProps.value,
                  })
                )
              }}
              checked={cellProps.value ?? false}
            />
          ),
      },
      {
        Header: "Address",
        accessor: "location.address",
        filterable: true,
      },
      {
        Header: "GPS Latitude",
        accessor: "location.gps_latitude",
        filterable: true,
      },
      {
        Header: "GPS Longitude",
        accessor: "location.gps_longitude",
        filterable: true,
      },
      {
        Header: "GPS Radius",
        accessor: "location.gps_radius",
        filterable: true,
      },
      {
        Header: "Action",
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  const rowData = cellProps.row.original
                  handleRowClick(rowData)
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const rowData = cellProps.row.original
                  onClickDelete(rowData)
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </Link>
            </div>
          )
        },
      },
    ],
    []
  )

  useEffect(() => {
    dispatch(getBranches())
  }, [dispatch])

  const toggle = () => {
    setModal(!modal)
  }

  const handleRowClick = arg => {
    const tmp = {
      ...arg.location,
      ...arg,
    }

    setBranch(tmp)
    setIsEdit(true)

    toggle()
  }

  var node = useRef()
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page)
    }
  }

  //delete customer
  const [deleteModal, setDeleteModal] = useState(false)

  const onClickDelete = branch => {
    setBranch(branch)
    setDeleteModal(true)
  }

  const handleDeleteUser = () => {
    dispatch(onDeleteUser(branch.id))
    onPaginationPageChange(1)
    setDeleteModal(false)
  }

  const handleAddClick = () => {
    setIsEdit(false)
    toggle()
  }

  const keyField = "id"

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Branches" breadcrumbItem="Branches List" />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={branches}
                    isGlobalFilter={true}
                    isAddOptions="Branch"
                    handleAddOptionClick={handleAddClick}
                    customPageSize={10}
                    className="custom-header-css"
                  />

                  <Modal isOpen={modal} toggle={toggle}>
                    <ModalHeader toggle={toggle} tag="h4">
                      {!!isEdit ? "Edit Branch" : "Add Branch"}
                    </ModalHeader>
                    <ModalBody>
                      <Form
                        onSubmit={e => {
                          e.preventDefault()
                          validation.handleSubmit()
                          return false
                        }}
                      >
                        <Row>
                          <Col md={6}>
                            <Label className="form-label">Active</Label>
                            <div className="mb-3">
                              <ReactSwitch
                                onChange={() =>
                                  validation.setFieldValue(
                                    "active",
                                    !validation.values.active
                                  )
                                }
                                checked={validation.values.active}
                              />
                              <FormFeedback type="invalid">
                                {validation.errors.active}
                              </FormFeedback>
                            </div>
                          </Col>
                          <Col xs={6}>
                            <div className="mb-3">
                              <Label className="form-label">Name</Label>
                              <Input
                                name="name"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.name || ""}
                                invalid={
                                  validation.touched.name &&
                                  validation.errors.name
                                    ? true
                                    : false
                                }
                              />
                              <FormFeedback type="invalid">
                                {validation.errors.name}
                              </FormFeedback>
                            </div>
                          </Col>
                          <Col md={12}>
                            <div className="mb-3">
                              <Label className="form-label">Address</Label>
                              <Input
                                name="address"
                                type="textarea"
                                rows="3"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.address || ""}
                                invalid={
                                  validation.touched.address &&
                                  validation.errors.address
                                    ? true
                                    : false
                                }
                              />
                              <FormFeedback type="invalid">
                                {validation.errors.address}
                              </FormFeedback>
                            </div>
                          </Col>
                          <Col md={6}>
                            <div className="mb-3">
                              <Label className="form-label">GPS Latitude</Label>
                              <Input
                                name="gps_latitude"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.gps_latitude || ""}
                                invalid={
                                  validation.touched.gps_latitude &&
                                  validation.errors.gps_latitude
                                    ? true
                                    : false
                                }
                              />
                              <FormFeedback type="invalid">
                                {validation.errors.gps_latitude}
                              </FormFeedback>
                            </div>
                          </Col>
                          <Col md={6}>
                            <div className="mb-3">
                              <Label className="form-label">
                                GPS Longitude
                              </Label>
                              <Input
                                name="gps_longitude"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.gps_longitude || ""}
                                invalid={
                                  validation.touched.gps_longitude &&
                                  validation.errors.gps_longitude
                                    ? true
                                    : false
                                }
                              />
                              <FormFeedback type="invalid">
                                {validation.errors.gps_longitude}
                              </FormFeedback>
                            </div>
                          </Col>
                          <Col md={12}>
                            <div className="mb-3">
                              <Label className="form-label">GPS Radius</Label>
                              <Input
                                name="gps_radius"
                                type="number"
                                min="0"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.gps_radius || ""}
                                invalid={
                                  validation.touched.gps_radius &&
                                  validation.errors.gps_radius
                                    ? true
                                    : false
                                }
                              />
                              <FormFeedback type="invalid">
                                {validation.errors.gps_radius}
                              </FormFeedback>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <div className="text-end">
                              <button type="submit" className="btn btn-success">
                                Save
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </ModalBody>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(BranchesList)
