import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { useHistory } from "react-router-dom"
import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  Row,
  Spinner,
  Table,
} from "reactstrap"
import Switch from "react-switch"

import Select from "react-select"
import { useDispatch, useSelector } from "react-redux"
import {
  addNewWorkPlan,
  deleteWorkPlan,
  getWorkPlanDetail,
  getWorkPlanDetailFail,
  updateWorkPlan,
} from "store/actions"
import { useFormik } from "formik"
import * as Yup from "yup"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import DeleteModal from "components/Common/DeleteModal"
import {
  DeductionPenalties,
  EmptyWorkPlan,
  WorkPlanTypes,
} from "constants/types"
import { withTranslation } from "react-i18next"
import { find } from "lodash"

const WorkPlansCreate = props => {
  //meta title
  document.title = "Create New WorkPlan | React Admin & Dashboard Template"
  const history = useHistory()
  const dispatch = useDispatch()
  const {
    match: { params },
  } = props
  const [deleteModal, setDeleteModal] = useState(false)
  const { loading, error, workPlanDetail } = useSelector(state => ({
    loading: state.apiGlobal.loading,
    workPlanDetail: state.WorkPlans.workPlanDetail,
    error: state.WorkPlans.error,
  }))

  const getWorkPlanRules = (rules = {}) => {
    const ruleTypes = Object.keys(EmptyWorkPlan.rules)
    let tmp = { ...EmptyWorkPlan.rules }
    ruleTypes.forEach(type => {
      const found = find(rules, { type })
      if (found) {
        tmp[type] = found
      }
    })
    return tmp
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: workPlanDetail.id ?? "",
      name: workPlanDetail.name ?? "",
      type: workPlanDetail.type ?? "",
      schedule: workPlanDetail.schedule ?? EmptyWorkPlan.schedule,
      rules: getWorkPlanRules(workPlanDetail.rules),
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required("Please Enter WorkPlan Name"),
      type: Yup.string().required("Please Select WorkPlan Type"),
    }),
    onSubmit: values => {
      if (!formik.dirty) return
      const newWorkPlan = {
        ...values,
      }
      if (newWorkPlan.id) {
        dispatch(updateWorkPlan(newWorkPlan))
      } else {
        dispatch(addNewWorkPlan(newWorkPlan))
        history.push(`/work-plans-list`)
      }
    },
  })

  const handleDeleteEvent = async () => {
    dispatch(deleteWorkPlan(params.id))
    history.push("/work-plans-list")
  }

  useEffect(() => {
    if (params && params.id) {
      if (workPlanDetail.id != params.id) {
        dispatch(getWorkPlanDetail(params.id))
      }
    } else {
      dispatch(getWorkPlanDetailFail({}))
    }
  }, [params, getWorkPlanDetail])

  const getWorkPeriod = (schedule, day) => {
    let period = { start: "0", end: "0" }
    if (schedule && schedule[day]) {
      const tmp = schedule[day]
      if (tmp?.off?.toString() === "0") {
        period = {
          start: tmp.start_time ?? "0",
          end: tmp.end_time ?? "0",
        }
      }
    }
    return period
  }

  const getFlexibleTime = (schedule, day) => {
    let flexible = { start: 0, end: 0 }
    if (schedule && schedule[day]) {
      const tmp = schedule[day]
      if (tmp?.off?.toString() === "0") {
        flexible = {
          start: tmp.start_flex ?? 0,
          end: tmp.end_flex ?? 0,
        }
      }
    }
    return flexible
  }

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteEvent}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs
            title="WorkPlans"
            breadcrumbItem={workPlanDetail.id ? "Edit" : "Create New"}
          />
          <Row>
            <Col lg="12">
              <Form onSubmit={formik.handleSubmit}>
                <Card>
                  <CardBody>
                    <CardTitle className="mb-4">
                      <Row className="align-items-center justify-content-between">
                        <Col lg="auto">
                          {formik.values.id ? "Edit" : "Create New"} WorkPlan
                          {formik.values.id && ` (${formik.values.id})`}
                        </Col>
                        <Col lg="auto">
                          <ButtonGroup>
                            <Button
                              disabled={loading}
                              type="submit"
                              color="primary"
                            >
                              Submit {loading && <Spinner size="sm" />}
                            </Button>
                            {formik.values.id && (
                              <Button
                                disabled={loading}
                                color="danger"
                                className="float-end"
                                onClick={() => setDeleteModal(true)}
                              >
                                Delete
                              </Button>
                            )}
                          </ButtonGroup>
                        </Col>
                      </Row>
                    </CardTitle>
                    <Row>
                      <Col md={6}>
                        <FormGroup className="mb-4">
                          <Label htmlFor="name">WorkPlan Name</Label>
                          <Input
                            name="name"
                            value={formik.values.name}
                            type="text"
                            className={
                              "form-control" +
                              (formik.errors.name && formik.touched.name
                                ? " is-invalid"
                                : "")
                            }
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            placeholder="Enter WorkPlan Name..."
                          />
                          <FormFeedback type="invalid">
                            {formik.errors.name}
                          </FormFeedback>
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup className="mb-4">
                          <Label form="owner">WorkPlan Type</Label>
                          <Select
                            isSearchable
                            options={WorkPlanTypes}
                            value={WorkPlanTypes.find(
                              wpt => wpt.value === formik.values.type
                            )}
                            onChange={option => {
                              formik.setFieldValue("type", option.value)
                            }}
                            placeholder="Select Type"
                            className={
                              formik.errors.type && formik.touched.type
                                ? " is-invalid form-control p-0 pe-5"
                                : ""
                            }
                          />
                          <FormFeedback type="invalid">
                            {formik.errors.type}
                          </FormFeedback>
                        </FormGroup>
                      </Col>
                      <Col md={12}>
                        <FormGroup className="mb-4">
                          <Label form="owner">Schedule</Label>
                          <Table bordered>
                            <thead>
                              <tr>
                                <th>Date</th>
                                {[
                                  "sun",
                                  "mon",
                                  "tue",
                                  "wed",
                                  "thu",
                                  "fri",
                                  "sat",
                                ].map(d => (
                                  <th key={`day-${d}`}>
                                    <FormGroup check inline>
                                      <Input
                                        type="checkbox"
                                        checked={
                                          formik.values.schedule[
                                            d
                                          ]?.off?.toString() === "0"
                                        }
                                        name={`schedule[${d}][off]`}
                                        onClick={e => {
                                          formik.setFieldValue(
                                            `schedule[${d}][off]`,
                                            e.target.checked ? 1 : 0
                                          )
                                        }}
                                        onChange={formik.handleChange}
                                      />
                                      <Label check>{props.t(d)}</Label>
                                    </FormGroup>
                                  </th>
                                ))}
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <th>Work period</th>
                                {[
                                  "sun",
                                  "mon",
                                  "tue",
                                  "wed",
                                  "thu",
                                  "fri",
                                  "sat",
                                ].map(d => (
                                  <td key={`wp-${d}`}>
                                    {formik.values.schedule[
                                      d
                                    ]?.off?.toString() === "0" && (
                                      <>
                                        <FormGroup>
                                          <Label size="sm">Start</Label>
                                          <Input
                                            bsSize="sm"
                                            type="time"
                                            name={`schedule[${d}][start_time]`}
                                            value={
                                              getWorkPeriod(
                                                formik.values.schedule,
                                                d
                                              ).start
                                            }
                                            onChange={formik.handleChange}
                                          />
                                        </FormGroup>
                                        <FormGroup>
                                          <Label size="sm">End</Label>
                                          <Input
                                            bsSize="sm"
                                            type="time"
                                            name={`schedule[${d}][end_time]`}
                                            value={
                                              getWorkPeriod(
                                                formik.values.schedule,
                                                d
                                              ).end
                                            }
                                            onChange={formik.handleChange}
                                          />
                                        </FormGroup>
                                      </>
                                    )}
                                  </td>
                                ))}
                              </tr>
                              <tr>
                                <th>Flexible Time</th>
                                {[
                                  "sun",
                                  "mon",
                                  "tue",
                                  "wed",
                                  "thu",
                                  "fri",
                                  "sat",
                                ].map(d => (
                                  <td key={`ft-${d}`}>
                                    {formik.values.schedule[
                                      d
                                    ]?.off?.toString() === "0" && (
                                      <>
                                        <FormGroup>
                                          <Label size="sm">Start</Label>
                                          <Input
                                            bsSize="sm"
                                            type="number"
                                            min={0}
                                            name={`schedule[${d}][start_flex]`}
                                            value={
                                              getFlexibleTime(
                                                formik.values.schedule,
                                                d
                                              ).start
                                            }
                                            onChange={formik.handleChange}
                                          />
                                        </FormGroup>
                                        <FormGroup>
                                          <Label size="sm">End</Label>
                                          <Input
                                            bsSize="sm"
                                            type="number"
                                            min={0}
                                            name={`schedule[${d}][end_flex]`}
                                            value={
                                              getFlexibleTime(
                                                formik.values.schedule,
                                                d
                                              ).end
                                            }
                                            onChange={formik.handleChange}
                                          />
                                        </FormGroup>
                                      </>
                                    )}
                                  </td>
                                ))}
                              </tr>
                            </tbody>
                          </Table>
                          <FormFeedback type="invalid">
                            {formik.errors.schedule}
                          </FormFeedback>
                        </FormGroup>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
                <Row>
                  <Col md={6}>
                    {["late_attendance", "early_leave", "absence_days"].map(
                      type => (
                        <Card key={type}>
                          <CardBody>
                            <CardTitle className="mb-4">
                              <Row>
                                <Col>{props.t(type)}</Col>
                                <Col lg="auto">
                                  <FormGroup check inline>
                                    <Switch
                                      checked={
                                        formik.values.rules[type].is_active
                                      }
                                      onChange={() => {
                                        formik.setFieldValue(
                                          `rules[${type}][is_active]`,
                                          !formik.values.rules[type].is_active
                                        )
                                      }}
                                    />
                                  </FormGroup>
                                </Col>
                              </Row>
                            </CardTitle>
                            {formik.values.rules[type].is_active && (
                              <Table striped>
                                <thead>
                                  <tr>
                                    {type !== "absence_days" && (
                                      <th className="w-25">Time (min)</th>
                                    )}
                                    <th>Deduction Penalties</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {formik.values.rules[type].rules.map(
                                    (rule, index) => (
                                      <tr key={index}>
                                        {type !== "absence_days" && (
                                          <td>
                                            <FormGroup>
                                              <InputGroup size="sm">
                                                <InputGroupText>
                                                  From
                                                </InputGroupText>
                                                <Input
                                                  type="number"
                                                  disabled={index > 0}
                                                  min={0}
                                                  value={rule.from}
                                                  name={`rules[${type}][rules][${index}][from]`}
                                                  onChange={formik.handleChange}
                                                  onBlur={formik.handleBlur}
                                                />
                                              </InputGroup>
                                            </FormGroup>
                                            <FormGroup>
                                              <InputGroup size="sm">
                                                <InputGroupText>
                                                  To
                                                </InputGroupText>
                                                <Input
                                                  type="number"
                                                  min={rule.from}
                                                  value={rule.to}
                                                  name={`rules[${type}][rules][${index}][to]`}
                                                  onChange={e => {
                                                    formik.handleChange(e)
                                                    if (
                                                      formik.values.rules[type]
                                                        .rules.length >
                                                      index + 1
                                                    ) {
                                                      formik.setFieldValue(
                                                        `rules[${type}][rules][${
                                                          index + 1
                                                        }][from]`,
                                                        parseInt(
                                                          e.target.value
                                                        ) + 1
                                                      )
                                                    }
                                                  }}
                                                  onBlur={formik.handleBlur}
                                                />
                                              </InputGroup>
                                            </FormGroup>
                                          </td>
                                        )}
                                        <td className="position-relative">
                                          {index > 0 && (
                                            <Button
                                              outline
                                              className="m-3 p-0 border-0 position-absolute end-0 top-0"
                                              size="sm"
                                              color="link"
                                              onClick={() => {
                                                let tmp =
                                                  formik.values.rules[type]
                                                    .rules
                                                tmp.splice(index, 1)
                                                formik.setFieldValue(
                                                  `rules[${type}][rules]`,
                                                  tmp
                                                )
                                              }}
                                            >
                                              <i className="fa fa-times"></i>
                                            </Button>
                                          )}
                                          <ul className="list-unstyled">
                                            {rule.penalties?.map(
                                              (penalty, time) => (
                                                <li key={time} className="mb-2">
                                                  <Label size="sm">
                                                    {props.t(
                                                      `penalty_time_${time}`
                                                    )}{" "}
                                                  </Label>
                                                  <Select
                                                    className="flex-grow-1"
                                                    options={DeductionPenalties}
                                                    getOptionLabel={option =>
                                                      props.t(option.label)
                                                    }
                                                    value={DeductionPenalties.find(
                                                      dp => dp.value === penalty
                                                    )}
                                                    onChange={option =>
                                                      formik.setFieldValue(
                                                        `rules[${type}][rules][${index}][penalties][${time}]`,
                                                        option.value
                                                      )
                                                    }
                                                  />
                                                  {time > 0 && (
                                                    <Button
                                                      disabled={time === 0}
                                                      className="float-end"
                                                      color="link"
                                                      size="sm"
                                                      onClick={() => {
                                                        let tmp =
                                                          formik.values.rules[
                                                            type
                                                          ].rules[index]
                                                            .penalties
                                                        tmp.splice(time, 1)
                                                        formik.setFieldValue(
                                                          `rules[${type}][rules][${index}][penalties]`,
                                                          tmp
                                                        )
                                                      }}
                                                    >
                                                      <i className="mdi mdi-minus"></i>{" "}
                                                      {props.t("Remove")}
                                                    </Button>
                                                  )}
                                                </li>
                                              )
                                            )}
                                            <li>
                                              {formik.values.rules[type].rules[
                                                index
                                              ].penalties?.length < 5 && (
                                                <Button
                                                  size="sm"
                                                  color="link"
                                                  onClick={() => {
                                                    let tmp =
                                                      formik.values.rules[type]
                                                        .rules[index].penalties
                                                    tmp.push("")
                                                    formik.setFieldValue(
                                                      `rules[${type}][rules][${index}][penalties]`,
                                                      tmp
                                                    )
                                                  }}
                                                >
                                                  <i className="mdi mdi-plus"></i>{" "}
                                                  Add Penalty
                                                </Button>
                                              )}
                                            </li>
                                          </ul>
                                        </td>
                                      </tr>
                                    )
                                  )}
                                  {type !== "absence_days" && (
                                    <tr>
                                      <td colSpan={2} className="text-center">
                                        <Button
                                          size="sm"
                                          color="success"
                                          onClick={() => {
                                            let tmp =
                                              formik.values.rules[type].rules
                                            let rule = { penalties: [""] }
                                            if (tmp.length) {
                                              rule.from =
                                                parseInt(
                                                  tmp[tmp.length - 1].to
                                                ) + 1
                                            }
                                            tmp.push(rule)
                                            formik.setFieldValue(
                                              `rules[${type}][rules]`,
                                              tmp
                                            )
                                          }}
                                        >
                                          <i className="mdi mdi-plus"></i>{" "}
                                          {props.t("Add Rule")}
                                        </Button>
                                      </td>
                                    </tr>
                                  )}
                                </tbody>
                              </Table>
                            )}
                          </CardBody>
                        </Card>
                      )
                    )}
                  </Col>
                  <Col md={6}>
                    {["missing_hours", "extra_hours"].map(type => (
                      <Card key={type}>
                        <CardBody>
                          <CardTitle className="mb-4">
                            <Row>
                              <Col>{props.t(type)}</Col>
                              <Col lg="auto">
                                <FormGroup check inline>
                                  <Switch
                                    checked={
                                      formik.values.rules[type].is_active
                                    }
                                    onChange={() => {
                                      formik.setFieldValue(
                                        `rules[${type}][is_active]`,
                                        !formik.values.rules[type].is_active
                                      )
                                    }}
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                          </CardTitle>
                          {formik.values.rules[type].is_active && (
                            <Table striped>
                              <thead>
                                <tr>
                                  <th>From (hour)</th>
                                  <th>To (hour)</th>
                                  <th>Hour factor</th>
                                </tr>
                              </thead>
                              <tbody>
                                {formik.values.rules[type].rules.map(
                                  (rule, index) => (
                                    <tr key={index}>
                                      <td>
                                        <Input
                                          type="number"
                                          disabled={index > 0}
                                          min={0}
                                          value={rule.from}
                                          name={`rules[${type}][rules][${index}][from]`}
                                          onChange={formik.handleChange}
                                          onBlur={formik.handleBlur}
                                        />
                                      </td>
                                      <td>
                                        <Input
                                          type="number"
                                          min={rule.from}
                                          value={rule.to}
                                          name={`rules[${type}][rules][${index}][to]`}
                                          onChange={e => {
                                            formik.handleChange(e)
                                            if (
                                              formik.values.rules[type].rules
                                                .length >
                                              index + 1
                                            ) {
                                              formik.setFieldValue(
                                                `rules[${type}][rules][${
                                                  index + 1
                                                }][from]`,
                                                parseInt(e.target.value) + 1
                                              )
                                            }
                                          }}
                                          onBlur={formik.handleBlur}
                                        />
                                      </td>
                                      <td>
                                        <Input
                                          type="number"
                                          min="1"
                                          step="any"
                                          value={rule.rate}
                                          name={`rules[${type}][rules][${index}][rate]`}
                                          onChange={formik.handleChange}
                                          onBlur={formik.handleBlur}
                                        />
                                        {index > 0 && (
                                          <Button
                                            className="float-end"
                                            size="sm"
                                            color="link"
                                            onClick={() => {
                                              let tmp =
                                                formik.values.rules[type]
                                              tmp.rules.splice(index, 1)
                                              formik.setFieldValue(
                                                `rules[${type}][rules]`,
                                                tmp.rules
                                              )
                                            }}
                                          >
                                            <i className="mdi mdi-minus"></i>{" "}
                                            {props.t("Remove")}
                                          </Button>
                                        )}
                                      </td>
                                    </tr>
                                  )
                                )}
                                <tr>
                                  <td colSpan={3} className="text-center">
                                    <Button
                                      size="sm"
                                      color="success"
                                      onClick={() => {
                                        let tmp =
                                          formik.values.rules[type].rules
                                        let rule = { penalties: [""] }
                                        if (tmp.length) {
                                          rule.from =
                                            parseInt(tmp[tmp.length - 1].to) + 1
                                        }
                                        tmp.push(rule)
                                        formik.setFieldValue(
                                          `rules[${type}][rules]`,
                                          tmp
                                        )
                                      }}
                                    >
                                      <i className="mdi mdi-plus"></i>{" "}
                                      {props.t("Add Rule")}
                                    </Button>
                                  </td>
                                </tr>
                              </tbody>
                            </Table>
                          )}
                        </CardBody>
                      </Card>
                    ))}
                    <Card>
                      <CardBody>
                        <CardTitle className="mb-4">
                          <Row>
                            <Col>{props.t("off_days")}</Col>
                            <Col lg="auto">
                              <FormGroup check inline>
                                <Switch
                                  checked={
                                    formik.values.rules.off_days.is_active
                                  }
                                  onChange={() => {
                                    formik.setFieldValue(
                                      `rules[off_days][is_active]`,
                                      !formik.values.rules.off_days.is_active
                                    )
                                  }}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                        </CardTitle>
                        {formik.values.rules.off_days.is_active && (
                          <>
                            <FormGroup>
                              <Label>
                                Set your overtime rate for each type of paid
                                time off.
                              </Label>
                            </FormGroup>
                            <FormGroup row className="align-items-center">
                              <Col md="3">
                                <Label>Holidays</Label>
                              </Col>
                              <Col>
                                <Input
                                  type="number"
                                  min="1"
                                  step="any"
                                  value={
                                    formik.values.rules.off_days.rules.holiday
                                  }
                                  name="rules[off_days][rules][holiday]"
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                />
                              </Col>
                            </FormGroup>
                            <FormGroup row className="align-items-center">
                              <Col md="3">
                                <Label>Weekend</Label>
                              </Col>
                              <Col>
                                <Input
                                  type="number"
                                  min="1"
                                  step="any"
                                  value={
                                    formik.values.rules.off_days.rules.weekend
                                  }
                                  name={`rules[off_days][rules][weekend]`}
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                />
                              </Col>
                            </FormGroup>
                            <FormGroup row className="align-items-center">
                              <Col md="3">
                                <Label>Vacation</Label>
                              </Col>
                              <Col>
                                <Input
                                  type="number"
                                  min="1"
                                  step="any"
                                  value={
                                    formik.values.rules.off_days.rules.vacation
                                  }
                                  name={`rules[off_days][rules][vacation]`}
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                />
                              </Col>
                            </FormGroup>
                          </>
                        )}
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(WorkPlansCreate)

WorkPlansCreate.propTypes = {
  t: PropTypes.any,
}
