import React, { useEffect, useState } from "react"
import { Link, useHistory } from "react-router-dom"
import Dropzone from "react-dropzone"
import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
  Spinner,
} from "reactstrap"

import Select from "react-select"
import { useDispatch, useSelector } from "react-redux"
import {
  addNewCompany,
  deleteCompany,
  getCompanyDetail,
  getCompanyDetailFail,
  getUsers,
  updateCompany,
} from "store/actions"
import { useFormik } from "formik"
import * as Yup from "yup"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import DeleteModal from "components/Common/DeleteModal"

const CompaniesCreate = props => {
  //meta title
  document.title = "Create New Company | React Admin & Dashboard Template"
  const history = useHistory()
  const dispatch = useDispatch()
  const {
    match: { params },
  } = props
  const [deleteModal, setDeleteModal] = useState(false)
  const { loading, users, error, companyDetail } = useSelector(state => ({
    loading: state.apiGlobal.loading,
    users: state.users.users,
    companyDetail: state.Companies.companyDetail,
    error: state.Companies.error,
  }))

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: companyDetail.id ?? "",
      name: companyDetail.name ?? "",
      user_id: companyDetail.user_id ?? "",
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required("Please Enter Company Name"),
      user_id: Yup.number().required("Please Select Company Owner"),
    }),
    onSubmit: values => {
      if (!formik.dirty) return
      const newCompany = {
        ...values,
      }
      if (newCompany.id) {
        dispatch(updateCompany(newCompany))
      } else {
        dispatch(addNewCompany(newCompany))
      }
    },
  })

  const handleDeleteEvent = async () => {
    dispatch(deleteCompany(params.id))
    history.push("/companies-grid")
  }

  useEffect(() => {
    if (params && params.id) {
      if (companyDetail.id != params.id) {
        dispatch(getCompanyDetail(params.id))
      }
    } else {
      dispatch(getCompanyDetailFail({}))
    }
  }, [params, getCompanyDetail])

  useEffect(() => {
    dispatch(getUsers())
  }, [dispatch])

  useEffect(() => {
    formik.setErrors(error?.errors ?? {})
    if (companyDetail.id && companyDetail.id != params?.id) {
      history.push(`/companies-create/${companyDetail.id}`)
    }
  }, [error, companyDetail])

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteEvent}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs
            title="Companies"
            breadcrumbItem={companyDetail.id ? "Edit" : "Create New"}
          />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">
                    {formik.values.id ? "Edit" : "Create New"} Company
                    {formik.values.id && ` (${formik.values.id})`}
                  </CardTitle>
                  <Form onSubmit={formik.handleSubmit}>
                    <FormGroup className="mb-4" row>
                      <Col lg="2">
                        <Label htmlFor="name">Company Name</Label>
                      </Col>
                      <Col lg="10">
                        <Input
                          name="name"
                          value={formik.values.name}
                          type="text"
                          className={
                            "form-control" +
                            (formik.errors.name && formik.touched.name
                              ? " is-invalid"
                              : "")
                          }
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          placeholder="Enter Company Name..."
                        />
                        <FormFeedback type="invalid">
                          {formik.errors.name}
                        </FormFeedback>
                      </Col>
                    </FormGroup>
                    <FormGroup className="mb-4" row>
                      <Col lg="2">
                        <Label htmlFor="description">Company Description</Label>
                      </Col>
                      <Col lg="10">
                        <Input
                          name="description"
                          type="textarea"
                          rows="3"
                          className={
                            "form-control" +
                            (formik.errors.description &&
                            formik.touched.description
                              ? " is-invalid"
                              : "")
                          }
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          placeholder="Enter Company Description..."
                        />
                        <FormFeedback type="invalid">
                          {formik.errors.description}
                        </FormFeedback>
                      </Col>
                    </FormGroup>

                    <FormGroup className="mb-4" row>
                      <Col lg="2">
                        <Label form="owner">Company Owner</Label>
                      </Col>
                      <Col lg="10">
                        <Select
                          isSearchable
                          options={users}
                          value={users.find(
                            u => u.id === formik.values.user_id
                          )}
                          getOptionLabel={option =>
                            option.name +
                            (option.profile &&
                              ` (${option.profile.first_name} ${option.profile.last_name})`)
                          }
                          getOptionValue={option => option.id}
                          onChange={option => {
                            formik.setFieldValue("user_id", option?.id)
                          }}
                          placeholder="Select User"
                          className={
                            formik.errors.user_id && formik.touched.user_id
                              ? " is-invalid form-control p-0 pe-5"
                              : ""
                          }
                        />
                        <FormFeedback type="invalid">
                          {formik.errors.user_id}
                        </FormFeedback>
                      </Col>
                    </FormGroup>
                    <Row className="justify-content-end">
                      <Col lg="auto">
                        <ButtonGroup>
                          <Button
                            disabled={loading}
                            type="submit"
                            color="primary"
                          >
                            Submit {loading && <Spinner size="sm" />}
                          </Button>
                          {formik.values.id && (
                            <Button
                              disabled={loading}
                              color="danger"
                              className="float-end"
                              onClick={() => setDeleteModal(true)}
                            >
                              Delete
                            </Button>
                          )}
                        </ButtonGroup>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default CompaniesCreate
