export const GET_WORK_PLANS = "GET_WORK_PLANS"
export const GET_WORK_PLANS_FAIL = "GET_WORK_PLANS_FAIL"
export const GET_WORK_PLANS_SUCCESS = "GET_WORK_PLANS_SUCCESS"

export const GET_WORK_PLAN_DETAIL = "GET_WORK_PLAN_DETAIL"
export const GET_WORK_PLAN_DETAIL_FAIL = "GET_WORK_PLAN_DETAIL_FAIL"
export const GET_WORK_PLAN_DETAIL_SUCCESS = "GET_WORK_PLAN_DETAIL_SUCCESS"

export const ADD_NEW_WORK_PLAN = "ADD_NEW_WORK_PLAN"
export const ADD_WORK_PLAN_SUCCESS = "ADD_WORK_PLAN_SUCCESS"
export const ADD_WORK_PLAN_FAIL = "ADD_WORK_PLAN_FAIL"

export const UPDATE_WORK_PLAN = "UPDATE_WORK_PLAN"
export const UPDATE_WORK_PLAN_SUCCESS = "UPDATE_WORK_PLAN_SUCCESS"
export const UPDATE_WORK_PLAN_FAIL = "UPDATE_WORK_PLAN_FAIL"

export const DELETE_WORK_PLAN = "DELETE_WORK_PLAN"
export const DELETE_WORK_PLAN_SUCCESS = "DELETE_WORK_PLAN_SUCCESS"
export const DELETE_WORK_PLAN_FAIL = "DELETE_WORK_PLAN_FAIL"
