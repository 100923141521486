const WorkPlanTypes = [
  { label: "Daily", value: "daily" },
  { label: "Task", value: "task" },
  { label: "Shift", value: "shift" },
]

const EmptyWorkPlan = {
  name: "",
  type: "",
  schedule: {
    sun: {
      off: 1,
    },
    mon: {
      off: 1,
    },
    tue: {
      off: 1,
    },
    wed: {
      off: 1,
    },
    thu: {
      off: 1,
    },
    fri: {
      off: 1,
    },
    sat: {
      off: 1,
    },
  },
  rules: {
    late_attendance: {
      type: "late_attendance",
      is_active: false,
      rules: [{ penalties: [""] }],
    },
    early_leave: {
      type: "early_leave",
      is_active: false,
      rules: [{ penalties: [""] }],
    },
    absence_days: {
      type: "absence_days",
      is_active: false,
      rules: [{ penalties: [""] }],
    },
    missing_hours: {
      type: "missing_hours",
      is_active: false,
      rules: [{ penalties: [""] }],
    },
    extra_hours: {
      type: "extra_hours",
      is_active: false,
      rules: [{ penalties: [""] }],
    },
    off_days: {
      type: "off_days",
      is_active: false,
      rules: {
        holiday: 1,
        weekend: 1,
        vacation: 1,
      },
    },
  },
}

const DeductionPenalties = [
  { label: "deduction_penalty_0", value: -1 },
  { label: "deduction_penalty_1", value: 0 },
  { label: "deduction_penalty_2", value: 2 },
  { label: "deduction_penalty_3", value: 4 },
  { label: "deduction_penalty_4", value: 8 },
  { label: "deduction_penalty_5", value: 16 },
  { label: "deduction_penalty_6", value: 24 },
]

const Religions = [
  { label: "Muslim", value: "muslim" },
  { label: "Christian", value: "christian" },
  { label: "Jewish", value: "jewish" },
]

const Genders = [
  { label: "Male", value: "male" },
  { label: "Female", value: "female" },
]

const BalanceEarned = [
  { label: "All at once", value: 0 },
  { label: "Each month", value: 1 },
]

const MultiLevelApprovals = [
  { value: "hr", label: "HR Manager" },
  {
    value: "direct",
    label: "Direct Manager",
  },
]

const PayrollExtraTypes = [
  { label: "fixed_deduction", value: "fixed_deduction" },
  { label: "percentage_deduction", value: "percentage_deduction" },
  { label: "fixed_allowance", value: "fixed_allowance" },
  { label: "percentage_allowance", value: "percentage_allowance" },
]

export {
  WorkPlanTypes,
  EmptyWorkPlan,
  DeductionPenalties,
  Religions,
  Genders,
  BalanceEarned,
  MultiLevelApprovals,
  PayrollExtraTypes,
}
