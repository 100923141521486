/* COMPANIES */
export const GET_COMPANIES = "GET_COMPANIES"
export const GET_COMPANIES_SUCCESS = "GET_COMPANIES_SUCCESS"
export const GET_COMPANIES_FAIL = "GET_COMPANIES_FAIL"

/* COMPANIES DETAIL*/
export const GET_COMPANY_DETAIL = "GET_COMPANY_DETAIL"
export const GET_COMPANY_DETAIL_SUCCESS = "GET_COMPANY_DETAIL_SUCCESS"
export const GET_COMPANY_DETAIL_FAIL = "GET_COMPANY_DETAIL_FAIL"

export const ADD_NEW_COMPANY = "ADD_NEW_COMPANY"
export const ADD_COMPANY_SUCCESS = "ADD_COMPANY_SUCCESS"
export const ADD_COMPANY_FAIL = "ADD_COMPANY_FAIL"

export const UPDATE_COMPANY = "UPDATE_COMPANY"
export const UPDATE_COMPANY_SUCCESS = "UPDATE_COMPANY_SUCCESS"
export const UPDATE_COMPANY_FAIL = "UPDATE_COMPANY_FAIL"

export const DELETE_COMPANY = "DELETE_COMPANY"
export const DELETE_COMPANY_SUCCESS = "DELETE_COMPANY_SUCCESS"
export const DELETE_COMPANY_FAIL = "DELETE_COMPANY_FAIL"
