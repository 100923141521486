import { call, put, takeEvery } from "redux-saga/effects"

// JobTitle Redux States
import {
  GET_JOB_TITLES,
  GET_JOB_TITLE_DETAIL,
  ADD_NEW_JOB_TITLE,
  DELETE_JOB_TITLE,
  UPDATE_JOB_TITLE,
} from "./actionTypes"
import {
  getJobTitlesSuccess,
  getJobTitlesFail,
  getJobTitleDetailSuccess,
  getJobTitleDetailFail,
  addJobTitleFail,
  addJobTitleSuccess,
  updateJobTitleSuccess,
  updateJobTitleFail,
  deleteJobTitleSuccess,
  deleteJobTitleFail,
} from "./actions"

//Include Both Helper File with needed methods
import { JobTitleApi } from "helpers/Api/job-titles"
import { apiError, apiLoaded, apiLoading } from "store/actions"

function* fetchJobTitles() {
  try {
    yield put(apiLoading())
    const response = yield call(JobTitleApi.all)
    if (response.success) {
      yield put(apiLoaded())
      yield put(getJobTitlesSuccess(response.data))
    } else {
      yield put(apiError(response.message))
      yield put(getJobTitlesFail(response.message))
    }
  } catch (error) {
    yield put(apiError(error.response?.data))
    yield put(getJobTitlesFail(error))
  }
}

function* fetchJobTitleDetail({ jobTitleId }) {
  try {
    yield put(apiLoading())
    if (jobTitleId === undefined) {
      yield put(getJobTitleDetailSuccess(null))
    } else {
      const response = yield call(JobTitleApi.show, jobTitleId)
      if (response.success) {
        yield put(apiLoaded())
        yield put(getJobTitleDetailSuccess(response.data))
      } else {
        yield put(apiError(response.message))
        yield put(getJobTitleDetailFail(response.message))
      }
    }
  } catch (error) {
    yield put(apiError(error.response?.data))
    yield put(getJobTitleDetailFail(error))
  }
}

function* onUpdateJobTitle({ payload: jobTitle }) {
  try {
    yield put(apiLoading())
    const response = yield call(JobTitleApi.update, jobTitle)
    if (response.success) {
      yield put(apiLoaded())
      yield put(updateJobTitleSuccess(response.data))
    } else {
      yield put(apiError(response.message))
      yield put(updateJobTitleFail(response.message))
    }
  } catch (error) {
    if (error.response) {
      yield put(apiError(error.response.message))
      yield put(updateJobTitleFail(error.response.data))
    } else {
      yield put(updateJobTitleFail(error))
      yield put(apiError(error.response?.data))
    }
  }
}

function* onDeleteJobTitle({ payload: jobTitle }) {
  try {
    yield put(apiLoading())
    const response = yield call(JobTitleApi.destroy, jobTitle)
    if (response.success) {
      yield put(apiLoaded())
      yield put(deleteJobTitleSuccess(response.data))
    } else {
      yield put(apiError(response.message))
      yield put(deleteJobTitleFail(response.message))
    }
  } catch (error) {
    yield put(apiError(error.response?.data))
    yield put(deleteJobTitleFail(error))
  }
}

function* onAddNewJobTitle({ payload: jobTitle }) {
  try {
    yield put(apiLoading())
    const response = yield call(JobTitleApi.create, jobTitle)
    if (response.success) {
      yield put(apiLoaded())
      yield put(addJobTitleSuccess(response.data))
    } else {
      yield put(apiError(response.message))
      yield put(addJobTitleFail(response.message))
    }
  } catch (error) {
    if (error.response) {
      yield put(addJobTitleFail(error.response.data))
      yield put(apiError(error.response.message))
    } else {
      yield put(addJobTitleFail(error))
      yield put(apiError(error.response?.data))
    }
  }
}

function* jobTitlesSaga() {
  yield takeEvery(GET_JOB_TITLES, fetchJobTitles)
  yield takeEvery(GET_JOB_TITLE_DETAIL, fetchJobTitleDetail)
  yield takeEvery(ADD_NEW_JOB_TITLE, onAddNewJobTitle)
  yield takeEvery(UPDATE_JOB_TITLE, onUpdateJobTitle)
  yield takeEvery(DELETE_JOB_TITLE, onDeleteJobTitle)
}

export default jobTitlesSaga
