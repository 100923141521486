import { combineReducers } from "redux"

// Front
import Layout from "./layout/reducer"

// Authentication
import Login from "./auth/login/reducer"
import Account from "./auth/register/reducer"
import ForgetPassword from "./auth/forgetpwd/reducer"
import Profile from "./auth/profile/reducer"

//E-commerce
import ecommerce from "./e-commerce/reducer"

//Calendar
import calendar from "./calendar/reducer"

//chat
import chat from "./chat/reducer"

//crypto
import crypto from "./crypto/reducer"

//invoices
import invoices from "./invoices/reducer"

//jobs
import JobReducer from "./jobs/reducer"

//projects
import projects from "./projects/reducer"

//tasks
import tasks from "./tasks/reducer"

//users
import users from "./users/reducer"

//mails
import mails from "./mails/reducer"

//Dashboard
import Dashboard from "./dashboard/reducer"

//Dasboard saas
import DashboardSaas from "./dashboard-saas/reducer"

//Companies
import Companies from "./companies/reducer"

//API Global
import apiGlobal from "./api-global/reducer"

//Departments
import Departments from "./departments/reducer"

//JobTitles
import JobTitles from "./job-titles/reducer"

//Branches
import Branches from "./branches/reducer"

//WorkPlans
import WorkPlans from "./work-plans/reducer"

//LeaveTypes
import LeaveTypes from "./leave-types/reducer"

//AttendanceTypes
import AttendanceTypes from "./attendance-types/reducer"

//PayrollExtras
import PayrollExtras from "./payroll-extras/reducer"

const rootReducer = combineReducers({
  // public
  apiGlobal,
  Layout,
  Login,
  Account,
  ForgetPassword,
  Profile,
  ecommerce,
  calendar,
  chat,
  mails,
  crypto,
  invoices,
  JobReducer,
  projects,
  tasks,
  users,
  Dashboard,
  DashboardSaas,
  Companies,
  Departments,
  JobTitles,
  Branches,
  WorkPlans,
  LeaveTypes,
  AttendanceTypes,
  PayrollExtras,
})

export default rootReducer
