import { call, put, takeEvery } from "redux-saga/effects"

// AttendanceType Redux States
import {
  GET_ATTENDANCE_TYPES,
  GET_ATTENDANCE_TYPE_DETAIL,
  ADD_NEW_ATTENDANCE_TYPE,
  DELETE_ATTENDANCE_TYPE,
  UPDATE_ATTENDANCE_TYPE,
} from "./actionTypes"
import {
  getAttendanceTypesSuccess,
  getAttendanceTypesFail,
  getAttendanceTypeDetailSuccess,
  getAttendanceTypeDetailFail,
  addAttendanceTypeFail,
  addAttendanceTypeSuccess,
  updateAttendanceTypeSuccess,
  updateAttendanceTypeFail,
  deleteAttendanceTypeSuccess,
  deleteAttendanceTypeFail,
} from "./actions"

//Include Both Helper File with needed methods
import { AttendanceTypeApi } from "helpers/Api/attendance-types"
import { apiError, apiLoaded, apiLoading } from "store/actions"

function* fetchAttendanceType() {
  try {
    yield put(apiLoading())
    const response = yield call(AttendanceTypeApi.all)
    if (response.success) {
      yield put(apiLoaded())
      yield put(getAttendanceTypesSuccess(response.data))
    } else {
      yield put(apiError(response.message))
      yield put(getAttendanceTypesFail(response.message))
    }
  } catch (error) {
    yield put(apiError(error.response?.data))
    yield put(getAttendanceTypesFail(error))
  }
}

function* fetchAttendanceTypeDetail({ attendanceTypeId }) {
  try {
    yield put(apiLoading())
    if (attendanceTypeId === undefined) {
      yield put(getAttendanceTypeDetailSuccess(null))
    } else {
      const response = yield call(AttendanceTypeApi.show, attendanceTypeId)
      if (response.success) {
        yield put(apiLoaded())
        yield put(getAttendanceTypeDetailSuccess(response.data))
      } else {
        yield put(apiError(response.message))
        yield put(getAttendanceTypeDetailFail(response.message))
      }
    }
  } catch (error) {
    yield put(apiError(error.response?.data))
    yield put(getAttendanceTypeDetailFail(error))
  }
}

function* onUpdateAttendanceType({ payload: attendanceType }) {
  try {
    yield put(apiLoading())
    const response = yield call(AttendanceTypeApi.update, attendanceType)
    if (response.success) {
      yield put(apiLoaded())
      yield put(updateAttendanceTypeSuccess(response.data))
    } else {
      yield put(apiError(response.message))
      yield put(updateAttendanceTypeFail(response.message))
    }
  } catch (error) {
    if (error.response) {
      yield put(apiError(error.response.message))
      yield put(updateAttendanceTypeFail(error.response.data))
    } else {
      yield put(updateAttendanceTypeFail(error))
      yield put(apiError(error.response?.data))
    }
  }
}

function* onDeleteAttendanceType({ payload: attendanceType }) {
  try {
    yield put(apiLoading())
    const response = yield call(AttendanceTypeApi.destroy, attendanceType)
    if (response.success) {
      yield put(apiLoaded())
      yield put(deleteAttendanceTypeSuccess(response.data))
    } else {
      yield put(apiError(response.message))
      yield put(deleteAttendanceTypeFail(response.message))
    }
  } catch (error) {
    yield put(apiError(error.response?.data))
    yield put(deleteAttendanceTypeFail(error))
  }
}

function* onAddNewAttendanceType({ payload: attendanceType }) {
  try {
    yield put(apiLoading())
    const response = yield call(AttendanceTypeApi.create, attendanceType)
    if (response.success) {
      yield put(apiLoaded())
      yield put(addAttendanceTypeSuccess(response.data))
    } else {
      yield put(apiError(response.message))
      yield put(addAttendanceTypeFail(response.message))
    }
  } catch (error) {
    if (error.response) {
      yield put(addAttendanceTypeFail(error.response.data))
      yield put(apiError(error.response.message))
    } else {
      yield put(addAttendanceTypeFail(error))
      yield put(apiError(error.response?.data))
    }
  }
}

function* attendanceTypesSaga() {
  yield takeEvery(GET_ATTENDANCE_TYPES, fetchAttendanceType)
  yield takeEvery(GET_ATTENDANCE_TYPE_DETAIL, fetchAttendanceTypeDetail)
  yield takeEvery(ADD_NEW_ATTENDANCE_TYPE, onAddNewAttendanceType)
  yield takeEvery(UPDATE_ATTENDANCE_TYPE, onUpdateAttendanceType)
  yield takeEvery(DELETE_ATTENDANCE_TYPE, onDeleteAttendanceType)
}

export default attendanceTypesSaga
