import {
  GET_LEAVE_TYPES,
  GET_LEAVE_TYPES_FAIL,
  GET_LEAVE_TYPES_SUCCESS,
  GET_LEAVE_TYPE_DETAIL,
  GET_LEAVE_TYPE_DETAIL_FAIL,
  GET_LEAVE_TYPE_DETAIL_SUCCESS,
  ADD_NEW_LEAVE_TYPE,
  ADD_LEAVE_TYPE_SUCCESS,
  ADD_LEAVE_TYPE_FAIL,
  UPDATE_LEAVE_TYPE,
  UPDATE_LEAVE_TYPE_SUCCESS,
  UPDATE_LEAVE_TYPE_FAIL,
  DELETE_LEAVE_TYPE,
  DELETE_LEAVE_TYPE_SUCCESS,
  DELETE_LEAVE_TYPE_FAIL,
} from "./actionTypes"

export const getLeaveTypes = () => ({
  type: GET_LEAVE_TYPES,
})

export const getLeaveTypesSuccess = companies => ({
  type: GET_LEAVE_TYPES_SUCCESS,
  payload: companies,
})

export const getLeaveTypesFail = error => ({
  type: GET_LEAVE_TYPES_FAIL,
  payload: error,
})

export const getLeaveTypeDetail = leaveTypeId => ({
  type: GET_LEAVE_TYPE_DETAIL,
  leaveTypeId,
})

export const getLeaveTypeDetailSuccess = leaveTypeDetails => ({
  type: GET_LEAVE_TYPE_DETAIL_SUCCESS,
  payload: leaveTypeDetails,
})

export const getLeaveTypeDetailFail = error => ({
  type: GET_LEAVE_TYPE_DETAIL_FAIL,
  payload: error,
})

export const addNewLeaveType = leaveType => ({
  type: ADD_NEW_LEAVE_TYPE,
  payload: leaveType,
})

export const addLeaveTypeSuccess = leaveType => ({
  type: ADD_LEAVE_TYPE_SUCCESS,
  payload: leaveType,
})

export const addLeaveTypeFail = error => ({
  type: ADD_LEAVE_TYPE_FAIL,
  payload: error,
})

export const updateLeaveType = leaveType => ({
  type: UPDATE_LEAVE_TYPE,
  payload: leaveType,
})

export const updateLeaveTypeSuccess = leaveType => ({
  type: UPDATE_LEAVE_TYPE_SUCCESS,
  payload: leaveType,
})

export const updateLeaveTypeFail = error => ({
  type: UPDATE_LEAVE_TYPE_FAIL,
  payload: error,
})

export const deleteLeaveType = leaveType => ({
  type: DELETE_LEAVE_TYPE,
  payload: leaveType,
})

export const deleteLeaveTypeSuccess = leaveType => ({
  type: DELETE_LEAVE_TYPE_SUCCESS,
  payload: leaveType,
})

export const deleteLeaveTypeFail = error => ({
  type: DELETE_LEAVE_TYPE_FAIL,
  payload: error,
})
