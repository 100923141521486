import {
  GET_WORK_PLANS,
  GET_WORK_PLANS_FAIL,
  GET_WORK_PLANS_SUCCESS,
  GET_WORK_PLAN_DETAIL,
  GET_WORK_PLAN_DETAIL_FAIL,
  GET_WORK_PLAN_DETAIL_SUCCESS,
  ADD_NEW_WORK_PLAN,
  ADD_WORK_PLAN_SUCCESS,
  ADD_WORK_PLAN_FAIL,
  UPDATE_WORK_PLAN,
  UPDATE_WORK_PLAN_SUCCESS,
  UPDATE_WORK_PLAN_FAIL,
  DELETE_WORK_PLAN,
  DELETE_WORK_PLAN_SUCCESS,
  DELETE_WORK_PLAN_FAIL,
} from "./actionTypes"

export const getWorkPlans = () => ({
  type: GET_WORK_PLANS,
})

export const getWorkPlansSuccess = workPlans => ({
  type: GET_WORK_PLANS_SUCCESS,
  payload: workPlans,
})

export const getWorkPlansFail = error => ({
  type: GET_WORK_PLANS_FAIL,
  payload: error,
})

export const getWorkPlanDetail = workPlanId => ({
  type: GET_WORK_PLAN_DETAIL,
  workPlanId,
})

export const getWorkPlanDetailSuccess = workPlanDetails => ({
  type: GET_WORK_PLAN_DETAIL_SUCCESS,
  payload: workPlanDetails,
})

export const getWorkPlanDetailFail = error => ({
  type: GET_WORK_PLAN_DETAIL_FAIL,
  payload: error,
})

export const addNewWorkPlan = workPlan => ({
  type: ADD_NEW_WORK_PLAN,
  payload: workPlan,
})

export const addWorkPlanSuccess = workPlan => ({
  type: ADD_WORK_PLAN_SUCCESS,
  payload: workPlan,
})

export const addWorkPlanFail = error => ({
  type: ADD_WORK_PLAN_FAIL,
  payload: error,
})

export const updateWorkPlan = workPlan => ({
  type: UPDATE_WORK_PLAN,
  payload: workPlan,
})

export const updateWorkPlanSuccess = workPlan => ({
  type: UPDATE_WORK_PLAN_SUCCESS,
  payload: workPlan,
})

export const updateWorkPlanFail = error => ({
  type: UPDATE_WORK_PLAN_FAIL,
  payload: error,
})

export const deleteWorkPlan = workPlan => ({
  type: DELETE_WORK_PLAN,
  payload: workPlan,
})

export const deleteWorkPlanSuccess = workPlan => ({
  type: DELETE_WORK_PLAN_SUCCESS,
  payload: workPlan,
})

export const deleteWorkPlanFail = error => ({
  type: DELETE_WORK_PLAN_FAIL,
  payload: error,
})
