import { call, put, takeEvery } from "redux-saga/effects"

// LeaveType Redux States
import {
  GET_LEAVE_TYPES,
  GET_LEAVE_TYPE_DETAIL,
  ADD_NEW_LEAVE_TYPE,
  DELETE_LEAVE_TYPE,
  UPDATE_LEAVE_TYPE,
} from "./actionTypes"
import {
  getLeaveTypesSuccess,
  getLeaveTypesFail,
  getLeaveTypeDetailSuccess,
  getLeaveTypeDetailFail,
  addLeaveTypeFail,
  addLeaveTypeSuccess,
  updateLeaveTypeSuccess,
  updateLeaveTypeFail,
  deleteLeaveTypeSuccess,
  deleteLeaveTypeFail,
} from "./actions"

//Include Both Helper File with needed methods
import { LeaveTypeApi } from "helpers/Api/leave-types"
import { apiError, apiLoaded, apiLoading } from "store/actions"

function* fetchLeaveType() {
  try {
    yield put(apiLoading())
    const response = yield call(LeaveTypeApi.all)
    if (response.success) {
      yield put(apiLoaded())
      yield put(getLeaveTypesSuccess(response.data))
    } else {
      yield put(apiError(response.message))
      yield put(getLeaveTypesFail(response.message))
    }
  } catch (error) {
    yield put(apiError(error.response?.data))
    yield put(getLeaveTypesFail(error))
  }
}

function* fetchLeaveTypeDetail({ leaveTypeId }) {
  try {
    yield put(apiLoading())
    if (leaveTypeId === undefined) {
      yield put(getLeaveTypeDetailSuccess(null))
    } else {
      const response = yield call(LeaveTypeApi.show, leaveTypeId)
      if (response.success) {
        yield put(apiLoaded())
        yield put(getLeaveTypeDetailSuccess(response.data))
      } else {
        yield put(apiError(response.message))
        yield put(getLeaveTypeDetailFail(response.message))
      }
    }
  } catch (error) {
    yield put(apiError(error.response?.data))
    yield put(getLeaveTypeDetailFail(error))
  }
}

function* onUpdateLeaveType({ payload: leaveType }) {
  try {
    yield put(apiLoading())
    const response = yield call(LeaveTypeApi.update, leaveType)
    if (response.success) {
      yield put(apiLoaded())
      yield put(updateLeaveTypeSuccess(response.data))
    } else {
      yield put(apiError(response.message))
      yield put(updateLeaveTypeFail(response.message))
    }
  } catch (error) {
    if (error.response) {
      yield put(apiError(error.response.message))
      yield put(updateLeaveTypeFail(error.response.data))
    } else {
      yield put(updateLeaveTypeFail(error))
      yield put(apiError(error.response?.data))
    }
  }
}

function* onDeleteLeaveType({ payload: leaveType }) {
  try {
    yield put(apiLoading())
    const response = yield call(LeaveTypeApi.destroy, leaveType)
    if (response.success) {
      yield put(apiLoaded())
      yield put(deleteLeaveTypeSuccess(response.data))
    } else {
      yield put(apiError(response.message))
      yield put(deleteLeaveTypeFail(response.message))
    }
  } catch (error) {
    yield put(apiError(error.response?.data))
    yield put(deleteLeaveTypeFail(error))
  }
}

function* onAddNewLeaveType({ payload: leaveType }) {
  try {
    yield put(apiLoading())
    const response = yield call(LeaveTypeApi.create, leaveType)
    if (response.success) {
      yield put(apiLoaded())
      yield put(addLeaveTypeSuccess(response.data))
    } else {
      yield put(apiError(response.message))
      yield put(addLeaveTypeFail(response.message))
    }
  } catch (error) {
    if (error.response) {
      yield put(addLeaveTypeFail(error.response.data))
      yield put(apiError(error.response.message))
    } else {
      yield put(addLeaveTypeFail(error))
      yield put(apiError(error.response?.data))
    }
  }
}

function* leaveTypesSaga() {
  yield takeEvery(GET_LEAVE_TYPES, fetchLeaveType)
  yield takeEvery(GET_LEAVE_TYPE_DETAIL, fetchLeaveTypeDetail)
  yield takeEvery(ADD_NEW_LEAVE_TYPE, onAddNewLeaveType)
  yield takeEvery(UPDATE_LEAVE_TYPE, onUpdateLeaveType)
  yield takeEvery(DELETE_LEAVE_TYPE, onDeleteLeaveType)
}

export default leaveTypesSaga
