import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { useHistory, withRouter } from "react-router-dom"
import { isEmpty } from "lodash"
import { Button, Col, Container, Row } from "reactstrap"

//Import Common Components
import Breadcrumbs from "components/Common/Breadcrumb"
import DeleteModal from "components/Common/DeleteModal"

import { deleteWorkPlan, getWorkPlanDetail } from "store/work-plans/actions"
import WorkPlanDetail from "./work-plan-detail"
import TeamMembers from "./team-members"
// import OverviewChart from "./overview-chart"
// import AttachedFiles from "./attached-files"
// import Comments from "./comments"

//redux
import { useSelector, useDispatch } from "react-redux"

const WorkPlanOverview = props => {
  //meta title
  document.title = "WorkPlan Overview | React Admin & Dashboard Template"
  const history = useHistory()
  const [deleteModal, setDeleteModal] = useState(false)
  const dispatch = useDispatch()

  const { loading, error, workPlanDetail } = useSelector(state => ({
    loading: state.apiGlobal.loading,
    error: state.apiGlobal.error,
    workPlanDetail: state.Companies.workPlanDetail,
  }))

  const {
    match: { params },
  } = props

  useEffect(() => {
    if (params && params.id) {
      dispatch(getWorkPlanDetail(params.id))
    }
  }, [params, getWorkPlanDetail])

  useEffect(() => {
    if (!loading && !workPlanDetail.id && error.message) {
      history.goBack()
    }
  }, [loading, workPlanDetail])

  const handleDeleteEvent = async () => {
    dispatch(deleteWorkPlan(params.id))
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <DeleteModal
          show={deleteModal}
          onDeleteClick={handleDeleteEvent}
          onCloseClick={() => setDeleteModal(false)}
        />
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Companies" breadcrumbItem="WorkPlan Overview" />

          {!isEmpty(workPlanDetail) && (
            <>
              <Row>
                <Col lg="8">
                  <WorkPlanDetail workPlan={workPlanDetail} />
                </Col>

                <Col lg="4">
                  <Button
                    color="danger"
                    className="float-end"
                    onClick={() => setDeleteModal(true)}
                  >
                    Delete
                  </Button>
                  {/* <TeamMembers team={workPlanDetail.owner} /> */}
                </Col>
              </Row>

              {/* <Row>
                <Col lg="4">
                  <OverviewChart options={options} series={series} />
                </Col>

                <Col lg="4">
                  <AttachedFiles files={workPlanDetail.files} />
                </Col>

                <Col lg="4">
                  <Comments comments={workPlanDetail.comments} />
                </Col>
              </Row> */}
            </>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

WorkPlanOverview.propTypes = {
  match: PropTypes.object,
}

export default withRouter(WorkPlanOverview)
