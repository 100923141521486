import { call, put, takeEvery } from "redux-saga/effects"

// User Redux States
import {
  GET_USERS,
  GET_USER_DETAIL,
  ADD_NEW_USER,
  DELETE_USER,
  UPDATE_USER,
} from "./actionTypes"
import {
  getUsersSuccess,
  getUsersFail,
  getUserDetailSuccess,
  getUserDetailFail,
  addUserFail,
  addUserSuccess,
  updateUserSuccess,
  updateUserFail,
  deleteUserSuccess,
  deleteUserFail,
} from "./actions"

//Include Both Helper File with needed methods
import { UserApi } from "helpers/Api/users"

function* fetchUsers() {
  try {
    const response = yield call(UserApi.all)
    if (response.success) {
      yield put(getUsersSuccess(response.data))
    } else {
      yield put(getUsersFail(response.message))
    }
  } catch (error) {
    yield put(getUsersFail(error))
  }
}

function* fetchUserDetail({ companyId }) {
  try {
    if (companyId === undefined) {
      yield put(getUserDetailSuccess(null))
    } else {
      const response = yield call(UserApi.show, companyId)
      if (response.success) {
        yield put(getUserDetailSuccess(response.data))
      } else {
        yield put(getUserDetailFail(response.message))
      }
    }
  } catch (error) {
    yield put(getUserDetailFail(error))
  }
}

function* onUpdateUser({ payload: company }) {
  try {
    const response = yield call(UserApi.update, company)
    if (response.success) {
      yield put(updateUserSuccess(response.data))
    } else {
      yield put(updateUserFail(response.message))
    }
  } catch (error) {
    yield put(updateUserFail(error))
  }
}

function* onDeleteUser({ payload: company }) {
  try {
    const response = yield call(UserApi.destroy, company)
    if (response.success) {
      yield put(deleteUserSuccess(response.data))
    } else {
      yield put(deleteUserFail(response.message))
    }
  } catch (error) {
    yield put(deleteUserFail(error))
  }
}

function* onAddNewUser({ payload: company }) {
  try {
    const response = yield call(UserApi.create, company)
    if (response.success) {
      yield put(addUserSuccess(response.data))
    } else {
      yield put(addUserFail(response.message))
    }
  } catch (error) {
    yield put(addUserFail(error))
  }
}

function* usersSaga() {
  yield takeEvery(GET_USERS, fetchUsers)
  yield takeEvery(GET_USER_DETAIL, fetchUserDetail)
  yield takeEvery(ADD_NEW_USER, onAddNewUser)
  yield takeEvery(UPDATE_USER, onUpdateUser)
  yield takeEvery(DELETE_USER, onDeleteUser)
}

export default usersSaga
