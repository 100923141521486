import React, { useEffect, useState, useRef, useMemo } from "react"
import { withRouter, Link } from "react-router-dom"
import TableContainer from "../../../components/Common/TableContainer"
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  UncontrolledTooltip,
  Input,
  Form,
  FormGroup,
  Button,
} from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"

import { Name, Email, JobTitle, Department } from "./contactlistCol"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import DeleteModal from "components/Common/DeleteModal"

import {
  getUsers as onGetUsers,
  addNewUser as onAddNewUser,
  updateUser as onUpdateUser,
  deleteUser as onDeleteUser,
} from "store/users/actions"
import { find, get, isEmpty } from "lodash"

//redux
import { useSelector, useDispatch } from "react-redux"
import ReactSelect from "react-select"
import guard from "helpers/jwt-token-access/apiGuard"
import {
  getBranches,
  getDepartments,
  getJobTitles,
  getWorkPlans,
} from "store/actions"

import ReactSwitch from "react-switch"

const isAdmin = guard === "admin"

const genders = [
  { label: "Male", value: "male" },
  { label: "Female", value: "female" },
]
const religions = [
  { label: "Muslim", value: "muslim" },
  { label: "Christian", value: "christian" },
  { label: "Jewish", value: "jewish" },
]
const contract_types = [
  { label: "Part Time", value: "part-time" },
  { label: "Full Time", value: "full-time" },
]

const ContactsList = props => {
  //meta title
  document.title = "User List | Skote - React Admin & Dashboard Template"

  const dispatch = useDispatch()
  const [contact, setContact] = useState()

  const EMPTY_USER = {
    name: contact?.name ?? "",
    email: contact?.email ?? "",
    first_name: contact?.first_name ?? "",
    last_name: contact?.last_name ?? "",
    gender: contact?.gender ?? "",
    religion: contact?.religion ?? "",
    birth_date: contact?.birth_date ?? "",
    mobile: contact?.mobile ?? "",
  }

  const EMPTY_EMPLOYEE = {
    email: contact?.email ?? "",
    branch_id: contact?.branch_id ?? "",
    department_id: contact?.department_id ?? "",
    job_title_id: contact?.job_title_id ?? "",
    employee_id: contact?.employee_id ?? undefined,
    join_date: contact?.join_date ?? "",
    contract_type: contact?.contract_type ?? "",
    contract_start: contact?.contract_start ?? "",
    contract_end: contact?.contract_end ?? "",
    probation_date: contact?.probation_date ?? "",
    work_plan_id: contact?.work_plan_id ?? undefined,
    salary: contact?.salary ?? 0,
    salary_basic: contact?.salary_basic ?? 0,
    salary_taxes: contact?.salary_taxes ?? 0,
    net_salary: contact?.net_salary ?? 0,
    salary_taxes: contact?.salary_taxes ?? 0,
    social_insurance: contact?.social_insurance ?? 0,
    social_insurance_employee: contact?.social_insurance_employee ?? 0,
    social_insurance_company: contact?.social_insurance_company ?? 0,
  }

  const USER_VALIDATION = {
    name: Yup.string().required("Please Enter user name"),
    email: Yup.string().required("Please Enter user email"),
    first_name: Yup.string().required("Please Enter user first name"),
    last_name: Yup.string().required("Please Enter user last name"),
    gender: Yup.string().required("Please Enter user gender"),
    birth_date: Yup.string().required("Please Enter user birth date"),
    mobile: Yup.string().required("Please Enter user mobile"),
    religion: Yup.string().required("Please Enter user religion"),
  }
  const EMPLOYEE_VALIDATION = {
    branch_id: Yup.string().required("Please select a branch"),
    department_id: Yup.string().required("Please select a department"),
    job_title_id: Yup.string().required("Please select a job title"),
    join_date: Yup.string().required("Please select the join date"),
    contract_type: Yup.string().required("Please select the contract type"),
    contract_start: Yup.string().required(
      "Please select the contract start date"
    ),
    contract_end: Yup.string().required("Please select the contract end date"),
    probation_date: Yup.string().required("Please select the probation date"),
  }

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: isAdmin ? EMPTY_USER : EMPTY_EMPLOYEE,
    validationSchema: Yup.object(
      isAdmin ? USER_VALIDATION : EMPLOYEE_VALIDATION
    ),
    onSubmit: values => {
      const tmp = isAdmin
        ? {
            id: contact.id,
            user: {
              name: values.name,
              email: values.email,
              password: values.password,
              password_confirmation: values.password_confirmation,
            },
            profile: {
              first_name: values.first_name,
              last_name: values.last_name,
              gender: values.gender,
              religion: values.religion,
              birth_date: values.birth_date,
              mobile: values.mobile,
            },
          }
        : {
            id: contact.id,
            ...values,
          }
      if (isEdit) {
        const updateUser = tmp
        // update user
        dispatch(onUpdateUser(updateUser))
        // validation.resetForm()
        // setIsEdit(false)
      } else {
        const newUser = tmp
        // save new user
        dispatch(onAddNewUser(newUser))
        // validation.resetForm()
      }
      // toggle()
    },
  })

  const { users, departments, jobTitles, branches, work_plans } = useSelector(
    state => ({
      users: state.users.users,
      departments: state.Departments.departments,
      work_plans: state.WorkPlans.workPlans,
      jobTitles: state.JobTitles.jobTitles,
      branches: state.Branches.branches,
    })
  )

  const [modal, setModal] = useState(false)
  const [salaryModal, setSalaryModal] = useState(false)
  const [gross, setGross] = useState(true)
  const [isEdit, setIsEdit] = useState(false)

  const calculateSalary = async () => {
    const decimal_points = 2
    const net_salary = !gross ? validation.values.net_salary : 0
    const gross_salary = gross ? validation.values.salary : 0
    const res = await fetch("http://localhost:8000/get_salary_details", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        decimal_points,
        net_salary,
        gross_salary,
      }),
    })
    const json = await res.json()
    console.log(json)
    validation.setFieldValue("social_insurance", json.basic)
    validation.setFieldValue("salary", json.gross)
    validation.setFieldValue("net_salary", json.net)
    validation.setFieldValue(
      "social_insurance_employee",
      json.social_insurance.employee
    )
    validation.setFieldValue(
      "social_insurance_company",
      json.social_insurance.company
    )
    validation.setFieldValue("salary_taxes", json.taxes)
  }

  const columns = useMemo(
    () => [
      {
        Header: "#",
        Cell: () => {
          return <input type="checkbox" className="form-check-input" />
        },
      },
      {
        Header: "Img",
        // accessor: "name",
        disableFilters: true,
        filterable: true,
        accessor: cellProps => (
          <>
            {!cellProps.img ? (
              <div className="avatar-xs">
                <span className="avatar-title rounded-circle">
                  {cellProps.name?.charAt(0)}
                </span>
              </div>
            ) : (
              <div>
                <img
                  className="rounded-circle avatar-xs"
                  src={cellProps.img}
                  alt=""
                />
              </div>
            )}
          </>
        ),
      },
      {
        Header: "Name",
        accessor: "name",
        filterable: true,
        Cell: cellProps => {
          return <Name {...cellProps} />
        },
      },
      {
        Header: "Email",
        accessor: "email",
        filterable: true,
        Cell: cellProps => {
          return <Email {...cellProps} />
        },
      },
      {
        Header: "Gender",
        accessor: "profile.gender",
        filterable: true,
      },
      {
        Header: "Religion",
        accessor: "profile.religion",
        filterable: true,
      },
      {
        Header: "Action",
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  const userData = cellProps.row.original
                  handleUserClick(userData)
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const userData = cellProps.row.original
                  onClickDelete(userData)
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </Link>
            </div>
          )
        },
      },
    ],
    []
  )

  const employeeColumns = useMemo(
    () => [
      {
        Header: "#",
        accessor: "id",
        disableFilters: true,
      },
      {
        Header: "Img",
        disableFilters: true,
        filterable: true,
        accessor: cellProps => (
          <div className="avatar-xs">
            <span className="avatar-title rounded-circle text-uppercase">
              {cellProps.user?.name?.charAt(0)}
            </span>
          </div>
        ),
      },
      {
        Header: "Name",
        accessor: cellProps =>
          `${cellProps.user?.profile?.first_name} ${cellProps.user?.profile?.last_name}`,
        filterable: true,
        Cell: cellProps => {
          return <Name {...cellProps} />
        },
      },
      {
        Header: "Email",
        accessor: "user.email",
        filterable: true,
        Cell: cellProps => {
          return <Email {...cellProps} />
        },
      },
      {
        Header: "Job Title",
        accessor: "job_title_id",
        disableFilters: true,
        Cell: cellProps => find(jobTitles, { id: cellProps.value })?.name,
      },
      {
        Header: "Department",
        accessor: "department_id",
        disableFilters: true,
        Cell: cellProps => find(departments, { id: cellProps.value })?.name,
      },
      {
        Header: "Work Plan",
        accessor: "work_plan_id",
        disableFilters: true,
        Cell: cellProps => find(work_plans, { id: cellProps.value })?.name,
      },
      {
        Header: "Action",
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              <Link
                to="#"
                className="text-info"
                onClick={() => {
                  const userData = cellProps.row.original
                  handleSalaryClick(userData)
                }}
              >
                <i
                  className="mdi mdi-cash-multiple font-size-18"
                  id="salary-tooltip"
                />
                <UncontrolledTooltip placement="top" target="salary-tooltip">
                  Salary
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  const userData = cellProps.row.original
                  handleUserClick(userData)
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edit-tooltip" />
                <UncontrolledTooltip placement="top" target="edit-tooltip">
                  Edit
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const userData = cellProps.row.original
                  onClickDelete(userData)
                }}
              >
                <i
                  className="mdi mdi-delete font-size-18"
                  id="delete-tooltip"
                />
                <UncontrolledTooltip placement="top" target="delete-tooltip">
                  Delete
                </UncontrolledTooltip>
              </Link>
            </div>
          )
        },
      },
    ],
    [jobTitles, departments, work_plans]
  )

  useEffect(() => {
    if (users && !users.length) {
      setIsEdit(false)
    }
  }, [dispatch, users])

  useEffect(() => {
    dispatch(onGetUsers())
    dispatch(getDepartments())
    dispatch(getJobTitles())
    dispatch(getBranches())
    dispatch(getWorkPlans())
  }, [dispatch])

  useEffect(() => {
    setContact(users)
    setIsEdit(false)
  }, [users])

  useEffect(() => {
    if (!isEmpty(users) && !!isEdit) {
      setContact(users)
      setIsEdit(false)
    }
  }, [users])

  const toggle = () => {
    setModal(!modal)
  }

  const toggleSalaryModal = () => {
    setSalaryModal(!salaryModal)
  }

  const handleSalaryClick = user => {
    setContact(user)
    toggleSalaryModal()
  }

  const handleUserClick = arg => {
    const user = !isAdmin
      ? {
          ...arg,
        }
      : {
          id: arg.id,
          name: arg.name,
          email: arg.email,
          first_name: arg.profile?.first_name,
          last_name: arg.profile?.last_name,
          gender: arg.profile?.gender,
          birth_date: arg.profile?.birth_date,
          mobile: arg.profile?.mobile,
        }

    setContact(user)
    setIsEdit(true)

    toggle()
  }

  var node = useRef()
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page)
    }
  }

  //delete customer
  const [deleteModal, setDeleteModal] = useState(false)

  const onClickDelete = users => {
    setContact(users)
    setDeleteModal(true)
  }

  const handleDeleteUser = () => {
    dispatch(onDeleteUser(contact.id))
    onPaginationPageChange(1)
    setDeleteModal(false)
  }

  const handleUserClicks = () => {
    setIsEdit(false)
    toggle()
  }

  const keyField = "id"

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Contacts" breadcrumbItem="User List" />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <TableContainer
                    columns={isAdmin ? columns : employeeColumns}
                    data={users}
                    isGlobalFilter={true}
                    isAddUserList={true}
                    handleUserClick={handleUserClicks}
                    customPageSize={10}
                    className="custom-header-css"
                  />

                  <Modal isOpen={salaryModal} toggle={toggleSalaryModal}>
                    <ModalHeader toggle={toggleSalaryModal}>
                      Salary Details
                    </ModalHeader>
                    <ModalBody>
                      <Row>
                        <Col md="3">
                          <Label>{gross ? "Gross" : "Net"}</Label>
                          <FormGroup>
                            <ReactSwitch
                              checked={gross}
                              onChange={() => {
                                setGross(!gross)
                                validation.setFieldValue("net_salary", 0)
                                validation.setFieldValue("salary", 0)
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup>
                            <Label>Salary</Label>
                            <Input
                              name={gross ? "salary" : "net_salary"}
                              type="number"
                              value={
                                gross
                                  ? validation.values.salary
                                  : validation.values.net_salary
                              }
                              onChange={validation.handleChange}
                            />
                          </FormGroup>
                        </Col>
                        <Col md="3">
                          <Label>Calculate</Label>
                          <FormGroup>
                            <Button
                              color="primary"
                              size="sm"
                              onClick={calculateSalary}
                            >
                              <i className="mdi mdi-calculator-variant"></i>
                            </Button>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="4">
                          <FormGroup>
                            <Label>Gross Salary</Label>
                            <Input
                              name="salary"
                              type="number"
                              value={validation.values.salary}
                              onChange={validation.handleChange}
                            />
                          </FormGroup>
                        </Col>
                        <Col md="4">
                          <FormGroup>
                            <Label>Net Salary</Label>
                            <Input
                              name="net_salary"
                              type="number"
                              value={validation.values.net_salary}
                              onChange={validation.handleChange}
                            />
                          </FormGroup>
                        </Col>
                        <Col md="4">
                          <FormGroup>
                            <Label>Taxes</Label>
                            <Input
                              name="salary_taxes"
                              type="number"
                              value={validation.values.salary_taxes}
                              onChange={validation.handleChange}
                            />
                          </FormGroup>
                        </Col>
                        <Col md="4">
                          <FormGroup>
                            <Label>Social Insurance</Label>
                            <Input
                              name="social_insurance"
                              type="number"
                              value={validation.values.social_insurance}
                              onChange={validation.handleChange}
                            />
                          </FormGroup>
                        </Col>
                        <Col md="4">
                          <FormGroup>
                            <Label>Employee Share</Label>
                            <Input
                              name="social_insurance_employee"
                              type="number"
                              value={
                                validation.values.social_insurance_employee
                              }
                              onChange={validation.handleChange}
                            />
                          </FormGroup>
                        </Col>
                        <Col md="4">
                          <FormGroup>
                            <Label>Company Share</Label>
                            <Input
                              name="social_insurance_company"
                              type="number"
                              value={validation.values.social_insurance_company}
                              onChange={validation.handleChange}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </ModalBody>
                  </Modal>

                  <Modal isOpen={modal} toggle={toggle}>
                    <ModalHeader toggle={toggle} tag="h4">
                      {!!isEdit ? "Edit User" : "Add User"}
                    </ModalHeader>
                    <ModalBody>
                      <Form
                        onSubmit={e => {
                          e.preventDefault()
                          validation.handleSubmit()
                          return false
                        }}
                      >
                        {isAdmin && (
                          <Row>
                            <Col md={6}>
                              <div className="mb-3">
                                <Label className="form-label">First Name</Label>
                                <Input
                                  name="first_name"
                                  type="text"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.first_name || ""}
                                  invalid={
                                    validation.touched.first_name &&
                                    validation.errors.first_name
                                      ? true
                                      : false
                                  }
                                />
                                <FormFeedback type="invalid">
                                  {validation.errors.first_name}
                                </FormFeedback>
                              </div>
                            </Col>
                            <Col md={6}>
                              <div className="mb-3">
                                <Label className="form-label">Last Name</Label>
                                <Input
                                  name="last_name"
                                  type="text"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.last_name || ""}
                                  invalid={
                                    validation.touched.last_name &&
                                    validation.errors.last_name
                                      ? true
                                      : false
                                  }
                                />
                                <FormFeedback type="invalid">
                                  {validation.errors.last_name}
                                </FormFeedback>
                              </div>
                            </Col>
                            <Col xs={6}>
                              <div className="mb-3">
                                <Label className="form-label">Username</Label>
                                <Input
                                  name="name"
                                  type="text"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.name || ""}
                                  invalid={
                                    validation.touched.name &&
                                    validation.errors.name
                                      ? true
                                      : false
                                  }
                                />
                                <FormFeedback type="invalid">
                                  {validation.errors.name}
                                </FormFeedback>
                              </div>
                            </Col>
                            <Col md={6}>
                              <div className="mb-3">
                                <Label className="form-label">Email</Label>
                                <Input
                                  name="email"
                                  label="Email"
                                  type="email"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.email || ""}
                                  invalid={
                                    validation.touched.email &&
                                    validation.errors.email
                                      ? true
                                      : false
                                  }
                                />
                                <FormFeedback type="invalid">
                                  {validation.errors.email}
                                </FormFeedback>
                              </div>
                            </Col>
                            <Col md={6}>
                              <div className="mb-3">
                                <Label className="form-label">Password</Label>
                                <Input
                                  name="password"
                                  label="Password"
                                  type="password"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.password || ""}
                                  invalid={
                                    validation.touched.password &&
                                    validation.errors.password
                                      ? true
                                      : false
                                  }
                                />
                                <FormFeedback type="invalid">
                                  {validation.errors.password}
                                </FormFeedback>
                              </div>
                            </Col>
                            <Col md={6}>
                              <div className="mb-3">
                                <Label className="form-label">
                                  Confirm Password
                                </Label>
                                <Input
                                  name="password_confirmation"
                                  label="Confirm Password"
                                  type="password"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={
                                    validation.values.password_confirmation ||
                                    ""
                                  }
                                  invalid={
                                    validation.touched.password_confirmation &&
                                    validation.errors.password_confirmation
                                      ? true
                                      : false
                                  }
                                />
                                <FormFeedback type="invalid">
                                  {validation.errors.password_confirmation}
                                </FormFeedback>
                              </div>
                            </Col>
                            <Col md={6}>
                              <div className="mb-3">
                                <Label className="form-label">Gender</Label>
                                <ReactSelect
                                  isSearchable
                                  options={genders}
                                  value={genders.find(
                                    g => g.value === validation.values.gender
                                  )}
                                  onChange={option =>
                                    validation.setFieldValue(
                                      "gender",
                                      option?.value
                                    )
                                  }
                                  placeholder="Select Gender"
                                  className={
                                    validation.errors.gender &&
                                    validation.touched.gender
                                      ? " is-invalid form-control p-0 pe-5"
                                      : ""
                                  }
                                />
                                <FormFeedback type="invalid">
                                  {validation.errors.gender}
                                </FormFeedback>
                              </div>
                            </Col>
                            <Col md={6}>
                              <div className="mb-3">
                                <Label className="form-label">Religion</Label>
                                <ReactSelect
                                  isSearchable
                                  options={religions}
                                  value={religions.find(
                                    g => g.value === validation.values.religion
                                  )}
                                  onChange={option =>
                                    validation.setFieldValue(
                                      "religion",
                                      option?.value
                                    )
                                  }
                                  placeholder="Select Gender"
                                  className={
                                    validation.errors.religion &&
                                    validation.touched.religion
                                      ? " is-invalid form-control p-0 pe-5"
                                      : ""
                                  }
                                />
                                <FormFeedback type="invalid">
                                  {validation.errors.religion}
                                </FormFeedback>
                              </div>
                            </Col>
                            <Col md={6}>
                              <div className="mb-3">
                                <Label className="form-label">Mobile</Label>
                                <Input
                                  name="mobile"
                                  label="Mobile"
                                  type="text"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.mobile || ""}
                                  invalid={
                                    validation.touched.mobile &&
                                    validation.errors.mobile
                                      ? true
                                      : false
                                  }
                                />
                                <FormFeedback type="invalid">
                                  {validation.errors.mobile}
                                </FormFeedback>
                              </div>
                            </Col>
                            <Col md={6}>
                              <div className="mb-3">
                                <Label className="form-label">
                                  Date of birth
                                </Label>
                                <Input
                                  name="birth_date"
                                  label="Date of birth"
                                  type="date"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.birth_date || ""}
                                  invalid={
                                    validation.touched.birth_date &&
                                    validation.errors.birth_date
                                      ? true
                                      : false
                                  }
                                />
                                <FormFeedback type="invalid">
                                  {validation.errors.birth_date}
                                </FormFeedback>
                              </div>
                            </Col>
                          </Row>
                        )}
                        {!isAdmin && (
                          <Row>
                            {!isEdit && (
                              <Col md={12}>
                                <div className="mb-3">
                                  <Label className="form-label">Email</Label>
                                  <Input
                                    name="email"
                                    label="Email"
                                    type="email"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.email || ""}
                                    invalid={
                                      validation.touched.email &&
                                      validation.errors.email
                                        ? true
                                        : false
                                    }
                                  />
                                  <FormFeedback type="invalid">
                                    {validation.errors.email}
                                  </FormFeedback>
                                </div>
                              </Col>
                            )}
                            <Col md={6}>
                              <div className="mb-3">
                                <Label className="form-label">Branch</Label>
                                <ReactSelect
                                  isSearchable
                                  options={branches}
                                  value={branches.find(
                                    branch =>
                                      branch.id === validation.values.branch_id
                                  )}
                                  getOptionLabel={option => option.name}
                                  getOptionValue={option => option.id}
                                  onChange={option =>
                                    validation.setFieldValue(
                                      "branch_id",
                                      option?.id
                                    )
                                  }
                                  placeholder="Select a Branch"
                                  className={
                                    validation.errors.branch_id &&
                                    validation.touched.branch_id
                                      ? " is-invalid form-control p-0 pe-5"
                                      : ""
                                  }
                                />
                                <FormFeedback type="invalid">
                                  {validation.errors.branch_id}
                                </FormFeedback>
                              </div>
                            </Col>
                            <Col md={6}>
                              <div className="mb-3">
                                <Label className="form-label">Department</Label>
                                <ReactSelect
                                  isSearchable
                                  options={departments}
                                  value={departments.find(
                                    department =>
                                      department.id ===
                                      validation.values.department_id
                                  )}
                                  getOptionLabel={option => option.name}
                                  getOptionValue={option => option.id}
                                  onChange={option =>
                                    validation.setFieldValue(
                                      "department_id",
                                      option?.id
                                    )
                                  }
                                  placeholder="Select a Department"
                                  className={
                                    validation.errors.department_id &&
                                    validation.touched.department_id
                                      ? " is-invalid form-control p-0 pe-5"
                                      : ""
                                  }
                                />
                                <FormFeedback type="invalid">
                                  {validation.errors.department_id}
                                </FormFeedback>
                              </div>
                            </Col>
                            <Col md={6}>
                              <div className="mb-3">
                                <Label className="form-label">Job Title</Label>
                                <ReactSelect
                                  isSearchable
                                  options={jobTitles}
                                  value={jobTitles.find(
                                    job =>
                                      job.id === validation.values.job_title_id
                                  )}
                                  getOptionLabel={option => option.name}
                                  getOptionValue={option => option.id}
                                  onChange={option =>
                                    validation.setFieldValue(
                                      "job_title_id",
                                      option?.id
                                    )
                                  }
                                  placeholder="Select a Job Title"
                                  className={
                                    validation.errors.job_title_id &&
                                    validation.touched.job_title_id
                                      ? " is-invalid form-control p-0 pe-5"
                                      : ""
                                  }
                                />
                                <FormFeedback type="invalid">
                                  {validation.errors.job_title_id}
                                </FormFeedback>
                              </div>
                            </Col>
                            <Col md={6}>
                              <div className="mb-3">
                                <Label className="form-label">
                                  Reporting To
                                </Label>
                                <ReactSelect
                                  isSearchable
                                  isClearable
                                  options={[]}
                                  value={[].find(
                                    employee =>
                                      employee.id ===
                                      validation.values.employee_id
                                  )}
                                  onChange={option =>
                                    validation.setFieldValue(
                                      "employee_id",
                                      option?.id
                                    )
                                  }
                                  placeholder="Select an Employee"
                                  className={
                                    validation.errors.employee_id &&
                                    validation.touched.employee_id
                                      ? " is-invalid form-control p-0 pe-5"
                                      : ""
                                  }
                                />
                                <FormFeedback type="invalid">
                                  {validation.errors.employee_id}
                                </FormFeedback>
                              </div>
                            </Col>
                            <Col md={6}>
                              <div className="mb-3">
                                <Label className="form-label">Join Date</Label>
                                <Input
                                  name="join_date"
                                  type="date"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.join_date || ""}
                                  invalid={
                                    validation.touched.join_date &&
                                    validation.errors.join_date
                                      ? true
                                      : false
                                  }
                                />
                                <FormFeedback type="invalid">
                                  {validation.errors.join_date}
                                </FormFeedback>
                              </div>
                            </Col>
                            <Col md={6}>
                              <div className="mb-3">
                                <Label className="form-label">
                                  Contract Type
                                </Label>
                                <ReactSelect
                                  isSearchable
                                  options={contract_types}
                                  value={contract_types.find(
                                    ct =>
                                      ct.value ===
                                      validation.values.contract_type
                                  )}
                                  onChange={option =>
                                    validation.setFieldValue(
                                      "contract_type",
                                      option?.value
                                    )
                                  }
                                  placeholder="Select Type"
                                  className={
                                    validation.errors.contract_type &&
                                    validation.touched.contract_type
                                      ? " is-invalid form-control p-0 pe-5"
                                      : ""
                                  }
                                />
                                <FormFeedback type="invalid">
                                  {validation.errors.contract_type}
                                </FormFeedback>
                              </div>
                            </Col>
                            <Col md={6}>
                              <div className="mb-3">
                                <Label className="form-label">
                                  Contract Start Date
                                </Label>
                                <Input
                                  name="contract_start"
                                  type="date"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.contract_start || ""}
                                  invalid={
                                    validation.touched.contract_start &&
                                    validation.errors.contract_start
                                      ? true
                                      : false
                                  }
                                />
                                <FormFeedback type="invalid">
                                  {validation.errors.contract_start}
                                </FormFeedback>
                              </div>
                            </Col>
                            <Col md={6}>
                              <div className="mb-3">
                                <Label className="form-label">
                                  Contract End Date
                                </Label>
                                <Input
                                  name="contract_end"
                                  type="date"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.contract_end || ""}
                                  invalid={
                                    validation.touched.contract_end &&
                                    validation.errors.contract_end
                                      ? true
                                      : false
                                  }
                                />
                                <FormFeedback type="invalid">
                                  {validation.errors.contract_end}
                                </FormFeedback>
                              </div>
                            </Col>
                            <Col md={6}>
                              <div className="mb-3">
                                <Label className="form-label">
                                  Probation End Date
                                </Label>
                                <Input
                                  name="probation_date"
                                  type="date"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.probation_date || ""}
                                  invalid={
                                    validation.touched.probation_date &&
                                    validation.errors.probation_date
                                      ? true
                                      : false
                                  }
                                />
                                <FormFeedback type="invalid">
                                  {validation.errors.probation_date}
                                </FormFeedback>
                              </div>
                            </Col>
                            <Col md={6}>
                              <div className="mb-3">
                                <Label className="form-label">Work Plan</Label>
                                <ReactSelect
                                  isSearchable
                                  options={work_plans}
                                  value={work_plans.find(
                                    work_plan =>
                                      work_plan.id ===
                                      validation.values.work_plan_id
                                  )}
                                  getOptionLabel={option => option.name}
                                  getOptionValue={option => option.id}
                                  onChange={option =>
                                    validation.setFieldValue(
                                      "work_plan_id",
                                      option?.id
                                    )
                                  }
                                  placeholder="Select a Department"
                                  className={
                                    validation.errors.work_plan_id &&
                                    validation.touched.work_plan_id
                                      ? " is-invalid form-control p-0 pe-5"
                                      : ""
                                  }
                                />
                                <FormFeedback type="invalid">
                                  {validation.errors.work_plan_id}
                                </FormFeedback>
                              </div>
                            </Col>
                          </Row>
                        )}
                        <Row>
                          <Col>
                            <div className="text-end">
                              <button
                                type="submit"
                                className="btn btn-success save-user"
                              >
                                Save
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </ModalBody>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(ContactsList)
