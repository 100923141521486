import { call, put, takeEvery } from "redux-saga/effects"

// Branch Redux States
import {
  GET_BRANCHES,
  GET_BRANCH_DETAIL,
  ADD_NEW_BRANCH,
  DELETE_BRANCH,
  UPDATE_BRANCH,
} from "./actionTypes"
import {
  getBranchesSuccess,
  getBranchesFail,
  getBranchDetailSuccess,
  getBranchDetailFail,
  addBranchFail,
  addBranchSuccess,
  updateBranchSuccess,
  updateBranchFail,
  deleteBranchSuccess,
  deleteBranchFail,
} from "./actions"

//Include Both Helper File with needed methods
import { BranchApi } from "helpers/Api/branches"
import { apiError, apiLoaded, apiLoading } from "store/actions"

function* fetchBranches() {
  try {
    yield put(apiLoading())
    const response = yield call(BranchApi.all)
    if (response.success) {
      yield put(apiLoaded())
      yield put(getBranchesSuccess(response.data))
    } else {
      yield put(apiError(response.message))
      yield put(getBranchesFail(response.message))
    }
  } catch (error) {
    yield put(apiError(error.response?.data))
    yield put(getBranchesFail(error))
  }
}

function* fetchBranchDetail({ branchId }) {
  try {
    yield put(apiLoading())
    if (branchId === undefined) {
      yield put(getBranchDetailSuccess(null))
    } else {
      const response = yield call(BranchApi.show, branchId)
      if (response.success) {
        yield put(apiLoaded())
        yield put(getBranchDetailSuccess(response.data))
      } else {
        yield put(apiError(response.message))
        yield put(getBranchDetailFail(response.message))
      }
    }
  } catch (error) {
    yield put(apiError(error.response?.data))
    yield put(getBranchDetailFail(error))
  }
}

function* onUpdateBranch({ payload: branch }) {
  try {
    yield put(apiLoading())
    const response = yield call(BranchApi.update, branch)
    if (response.success) {
      yield put(apiLoaded())
      yield put(updateBranchSuccess(response.data))
    } else {
      yield put(apiError(response.message))
      yield put(updateBranchFail(response.message))
    }
  } catch (error) {
    if (error.response) {
      yield put(apiError(error.response.message))
      yield put(updateBranchFail(error.response.data))
    } else {
      yield put(updateBranchFail(error))
      yield put(apiError(error.response?.data))
    }
  }
}

function* onDeleteBranch({ payload: branch }) {
  try {
    yield put(apiLoading())
    const response = yield call(BranchApi.destroy, branch)
    if (response.success) {
      yield put(apiLoaded())
      yield put(deleteBranchSuccess(response.data))
    } else {
      yield put(apiError(response.message))
      yield put(deleteBranchFail(response.message))
    }
  } catch (error) {
    yield put(apiError(error.response?.data))
    yield put(deleteBranchFail(error))
  }
}

function* onAddNewBranch({ payload: branch }) {
  try {
    yield put(apiLoading())
    const response = yield call(BranchApi.create, branch)
    if (response.success) {
      yield put(apiLoaded())
      yield put(addBranchSuccess(response.data))
    } else {
      yield put(apiError(response.message))
      yield put(addBranchFail(response.message))
    }
  } catch (error) {
    if (error.response) {
      yield put(addBranchFail(error.response.data))
      yield put(apiError(error.response.message))
    } else {
      yield put(addBranchFail(error))
      yield put(apiError(error.response?.data))
    }
  }
}

function* branchesSaga() {
  yield takeEvery(GET_BRANCHES, fetchBranches)
  yield takeEvery(GET_BRANCH_DETAIL, fetchBranchDetail)
  yield takeEvery(ADD_NEW_BRANCH, onAddNewBranch)
  yield takeEvery(UPDATE_BRANCH, onUpdateBranch)
  yield takeEvery(DELETE_BRANCH, onDeleteBranch)
}

export default branchesSaga
