import {
  GET_LEAVE_TYPES_FAIL,
  GET_LEAVE_TYPES_SUCCESS,
  GET_LEAVE_TYPE_DETAIL_FAIL,
  GET_LEAVE_TYPE_DETAIL_SUCCESS,
  ADD_LEAVE_TYPE_SUCCESS,
  ADD_LEAVE_TYPE_FAIL,
  UPDATE_LEAVE_TYPE_SUCCESS,
  UPDATE_LEAVE_TYPE_FAIL,
  DELETE_LEAVE_TYPE_SUCCESS,
  DELETE_LEAVE_TYPE_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  leaveTypes: [],
  leaveTypeDetail: {},
  error: {},
}

const LeaveTypes = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_LEAVE_TYPES_SUCCESS:
      return {
        ...state,
        leaveTypes: action.payload,
      }

    case GET_LEAVE_TYPES_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_LEAVE_TYPE_DETAIL_SUCCESS:
      return {
        ...state,
        leaveTypeDetail: action.payload,
      }

    case GET_LEAVE_TYPE_DETAIL_FAIL:
      return {
        ...state,
        leaveTypeDetail: null,
        error: action.payload,
      }

    case ADD_LEAVE_TYPE_SUCCESS:
      return {
        ...state,
        leaveTypes: [...state.leaveTypes, action.payload],
      }

    case ADD_LEAVE_TYPE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case UPDATE_LEAVE_TYPE_SUCCESS:
      return {
        ...state,
        leaveTypes: state.leaveTypes.map(leaveType =>
          leaveType.id.toString() === action.payload.id.toString()
            ? { leaveType, ...action.payload }
            : leaveType
        ),
      }

    case UPDATE_LEAVE_TYPE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_LEAVE_TYPE_SUCCESS:
      return {
        ...state,
        leaveTypes: state.leaveTypes.filter(
          leaveType => leaveType.id.toString() !== action.payload.id.toString()
        ),
      }

    case DELETE_LEAVE_TYPE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default LeaveTypes
