import {
  GET_JOB_TITLES,
  GET_JOB_TITLES_FAIL,
  GET_JOB_TITLES_SUCCESS,
  GET_JOB_TITLE_DETAIL,
  GET_JOB_TITLE_DETAIL_FAIL,
  GET_JOB_TITLE_DETAIL_SUCCESS,
  ADD_NEW_JOB_TITLE,
  ADD_JOB_TITLE_SUCCESS,
  ADD_JOB_TITLE_FAIL,
  UPDATE_JOB_TITLE,
  UPDATE_JOB_TITLE_SUCCESS,
  UPDATE_JOB_TITLE_FAIL,
  DELETE_JOB_TITLE,
  DELETE_JOB_TITLE_SUCCESS,
  DELETE_JOB_TITLE_FAIL,
} from "./actionTypes"

export const getJobTitles = () => ({
  type: GET_JOB_TITLES,
})

export const getJobTitlesSuccess = jobTitles => ({
  type: GET_JOB_TITLES_SUCCESS,
  payload: jobTitles,
})

export const getJobTitlesFail = error => ({
  type: GET_JOB_TITLES_FAIL,
  payload: error,
})

export const getJobTitleDetail = jobTitleId => ({
  type: GET_JOB_TITLE_DETAIL,
  jobTitleId,
})

export const getJobTitleDetailSuccess = jobTitleDetails => ({
  type: GET_JOB_TITLE_DETAIL_SUCCESS,
  payload: jobTitleDetails,
})

export const getJobTitleDetailFail = error => ({
  type: GET_JOB_TITLE_DETAIL_FAIL,
  payload: error,
})

export const addNewJobTitle = jobTitle => ({
  type: ADD_NEW_JOB_TITLE,
  payload: jobTitle,
})

export const addJobTitleSuccess = jobTitle => ({
  type: ADD_JOB_TITLE_SUCCESS,
  payload: jobTitle,
})

export const addJobTitleFail = error => ({
  type: ADD_JOB_TITLE_FAIL,
  payload: error,
})

export const updateJobTitle = jobTitle => ({
  type: UPDATE_JOB_TITLE,
  payload: jobTitle,
})

export const updateJobTitleSuccess = jobTitle => ({
  type: UPDATE_JOB_TITLE_SUCCESS,
  payload: jobTitle,
})

export const updateJobTitleFail = error => ({
  type: UPDATE_JOB_TITLE_FAIL,
  payload: error,
})

export const deleteJobTitle = jobTitle => ({
  type: DELETE_JOB_TITLE,
  payload: jobTitle,
})

export const deleteJobTitleSuccess = jobTitle => ({
  type: DELETE_JOB_TITLE_SUCCESS,
  payload: jobTitle,
})

export const deleteJobTitleFail = error => ({
  type: DELETE_JOB_TITLE_FAIL,
  payload: error,
})
