import React from "react"
import PropTypes from "prop-types"
import { Card, CardBody, Col, Row } from "reactstrap"
import img1 from "../../../assets/images/companies/img-1.png"

const CompanyDetail = ({ company }) => {
  return (
    <Card>
      <CardBody>
        <div className="d-flex">
          <img src={img1} alt="" className="avatar-sm me-4" />

          <div className="flex-grow-1 overflow-hidden">
            <h5 className="text-truncate font-size-15">{company.name}</h5>
            <p className="text-muted">{company.description}</p>
          </div>
        </div>

        <h5 className="font-size-15 mt-4">Company Details:</h5>

        <Row className="task-dates">
          <Col sm="4" xs="6">
            <div className="mt-4">
              <h5 className="font-size-14">
                <i className="bx bx-calendar me-1 text-primary" /> Created At
              </h5>
              <p className="text-muted mb-0">{company.created_at}</p>
            </div>
          </Col>

          <Col sm="4" xs="6">
            <div className="mt-4">
              <h5 className="font-size-14">
                <i className="bx bx-calendar me-1 text-primary" /> Updated At
              </h5>
              <p className="text-muted mb-0">{company.updated_at}</p>
            </div>
          </Col>
        </Row>
      </CardBody>
    </Card>
  )
}

CompanyDetail.propTypes = {
  company: PropTypes.object,
}

export default CompanyDetail
