import React, { useEffect, useState, useRef, useMemo } from "react"
import { withRouter, Link } from "react-router-dom"

import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  UncontrolledTooltip,
} from "reactstrap"

//Import Common Components
import Breadcrumbs from "components/Common/Breadcrumb"
import DeleteModal from "components/Common/DeleteModal"
import TableContainer from "components/Common/TableContainer"

//redux
import { useSelector, useDispatch } from "react-redux"

import { deleteWorkPlan, getWorkPlans } from "store/actions"

const WorkPlansList = props => {
  //meta title
  document.title = "WorkPlans | React Admin & Dashboard Template"

  const dispatch = useDispatch()
  const [workPlan, setWorkPlan] = useState({})

  const { workPlans } = useSelector(state => ({
    workPlans: state.WorkPlans.workPlans,
  }))

  const columns = useMemo(
    () => [
      {
        Header: "id",
        accessor: "id",
      },
      {
        Header: "Name",
        accessor: "name",
        filterable: true,
      },
      {
        Header: "Type",
        accessor: "type",
        filterable: true,
      },
      {
        Header: "Action",
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  const rowData = cellProps.row.original
                  handleRowClick(rowData)
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const rowData = cellProps.row.original
                  onClickDelete(rowData)
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </Link>
            </div>
          )
        },
      },
    ],
    []
  )

  useEffect(() => {
    dispatch(getWorkPlans())
  }, [dispatch])

  const handleRowClick = arg => {
    props.history.push(`/work-plans-create/${arg.id}`)
  }

  //delete customer
  const [deleteModal, setDeleteModal] = useState(false)

  const onClickDelete = workPlan => {
    setWorkPlan(workPlan)
    setDeleteModal(true)
  }

  const handleDeleteUser = () => {
    dispatch(deleteWorkPlan(workPlan.id))
    setDeleteModal(false)
  }

  const handleAddClick = () => {
    props.history.push(`/work-plans-create`)
  }

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="WorkPlans" breadcrumbItem="WorkPlans List" />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={workPlans}
                    isGlobalFilter={true}
                    isAddOptions="WorkPlan"
                    handleAddOptionClick={handleAddClick}
                    customPageSize={10}
                    className="custom-header-css"
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(WorkPlansList)
