import PropTypes from "prop-types"
import React from "react"
import { Link, useHistory } from "react-router-dom"
import { map } from "lodash"
import { Badge, Card, CardBody, Col, UncontrolledTooltip } from "reactstrap"
import companyImages from "assets/images/companies"

import guard from "helpers/jwt-token-access/apiGuard"
import { curOrg, setCurOrg } from "helpers/jwt-token-access/curOrg"

const isAdmin = guard === "admin"

const CardProject = ({ companies }) => {
  const history = useHistory()
  const handleCompanyClick = company => {
    if (isAdmin) {
      history.push(`/companies-create/${company?.id}`)
    } else {
      setCurOrg(company)
    }
  }

  return (
    <React.Fragment>
      {map(companies, (company, key) => (
        <Col xl="3" md="4" sm="6" key={key}>
          <Card onClick={() => handleCompanyClick(company)}>
            <CardBody>
              <div className="d-flex">
                <div className="avatar-md me-4">
                  <span className="avatar-title rounded-circle bg-light text-danger font-size-16">
                    <img src={companyImages["img1"]} alt="" height="30" />
                  </span>
                </div>
                <div className="flex-grow-1 overflow-hidden">
                  <h5 className="text-truncate font-size-15 text-dark">
                    {company?.name}
                  </h5>
                  <p className="text-muted mb-4">
                    {curOrg?.id === company.id && "Current"}
                  </p>

                  {company.owner && (
                    <div className="avatar-group">
                      <div
                        id={"member" + company.user_id}
                        className="avatar-group-item"
                      >
                        <div className="avatar-xs">
                          <span className="avatar-title rounded-circle text-uppercase">
                            {company.owner?.name.charAt(0)}
                          </span>
                        </div>
                        <UncontrolledTooltip
                          placement="top"
                          target={"member" + company.user_id}
                        >
                          {company.owner?.name}
                        </UncontrolledTooltip>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </CardBody>
            {/* <div className="px-4 py-3 border-top">
              <ul className="list-inline mb-0">
                <li className="list-inline-item me-3">
                  <Badge className={"bg-" + company?.color}>
                    {company?.status}
                  </Badge>
                </li>{" "}
                <li className="list-inline-item me-3" id="dueDate">
                  <i className="bx bx-calendar me-1" /> {company?.dueDate}
                  <UncontrolledTooltip placement="top" target="dueDate">
                    Due Date
                  </UncontrolledTooltip>
                </li>{" "}
                <li className="list-inline-item me-3" id="comments">
                  <i className="bx bx-comment-dots me-1" />{" "}
                  {company?.commentsCount}
                  <UncontrolledTooltip placement="top" target="comments">
                    Comments
                  </UncontrolledTooltip>
                </li>
              </ul>
            </div> */}
          </Card>
        </Col>
      ))}
    </React.Fragment>
  )
}

CardProject.propTypes = {
  companies: PropTypes.array,
}

export default CardProject
