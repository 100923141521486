import React, { useEffect, useState } from "react"
import { Col, Container, PaginationItem, PaginationLink, Row } from "reactstrap"
import { withRouter } from "react-router-dom"
import { map } from "lodash"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

//Import Cards
import CardCompany from "./card-company"

import { getCompanies } from "store/actions"

//redux
import { useSelector, useDispatch } from "react-redux"

const CompaniesGrid = props => {
  //meta title
  document.title = "Companies Grid | React Admin & Dashboard Template"

  const dispatch = useDispatch()

  const { companies } = useSelector(state => ({
    companies: state.Companies.companies,
  }))

  // const [page, setPage] = useState(1)
  // const [totalPage] = useState(5)

  useEffect(() => {
    dispatch(getCompanies())
  }, [dispatch])

  // const handlePageClick = page => {
  //   setPage(page)
  // }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Companies" breadcrumbItem="Companies Grid" />

          <Row>
            {/* Import Cards */}
            <CardCompany companies={companies} />
          </Row>

          {/* <Row>
            <Col lg="12">
              <ul className="pagination pagination-rounded justify-content-center mt-2 mb-5">
                <PaginationItem disabled={page === 1}>
                  <PaginationLink
                    previous
                    href="#"
                    onClick={() => handlePageClick(page - 1)}
                  />
                </PaginationItem>
                {map(Array(totalPage), (item, i) => (
                  <PaginationItem active={i + 1 === page} key={i}>
                    <PaginationLink
                      onClick={() => handlePageClick(i + 1)}
                      href="#"
                    >
                      {i + 1}
                    </PaginationLink>
                  </PaginationItem>
                ))}
                <PaginationItem disabled={page === totalPage}>
                  <PaginationLink
                    next
                    href="#"
                    onClick={() => handlePageClick(page + 1)}
                  />
                </PaginationItem>
              </ul>
            </Col>
          </Row> */}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(CompaniesGrid)
