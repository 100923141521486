/* DEPARTMENTS */
export const GET_DEPARTMENTS = "GET_DEPARTMENTS"
export const GET_DEPARTMENTS_SUCCESS = "GET_DEPARTMENTS_SUCCESS"
export const GET_DEPARTMENTS_FAIL = "GET_DEPARTMENTS_FAIL"

/* DEPARTMENTS DETAIL*/
export const GET_DEPARTMENT_DETAIL = "GET_DEPARTMENT_DETAIL"
export const GET_DEPARTMENT_DETAIL_SUCCESS = "GET_DEPARTMENT_DETAIL_SUCCESS"
export const GET_DEPARTMENT_DETAIL_FAIL = "GET_DEPARTMENT_DETAIL_FAIL"

export const ADD_NEW_DEPARTMENT = "ADD_NEW_DEPARTMENT"
export const ADD_DEPARTMENT_SUCCESS = "ADD_DEPARTMENT_SUCCESS"
export const ADD_DEPARTMENT_FAIL = "ADD_DEPARTMENT_FAIL"

export const UPDATE_DEPARTMENT = "UPDATE_DEPARTMENT"
export const UPDATE_DEPARTMENT_SUCCESS = "UPDATE_DEPARTMENT_SUCCESS"
export const UPDATE_DEPARTMENT_FAIL = "UPDATE_DEPARTMENT_FAIL"

export const DELETE_DEPARTMENT = "DELETE_DEPARTMENT"
export const DELETE_DEPARTMENT_SUCCESS = "DELETE_DEPARTMENT_SUCCESS"
export const DELETE_DEPARTMENT_FAIL = "DELETE_DEPARTMENT_FAIL"
