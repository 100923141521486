export const GET_LEAVE_TYPES = "GET_LEAVE_TYPES"
export const GET_LEAVE_TYPES_FAIL = "GET_LEAVE_TYPES_FAIL"
export const GET_LEAVE_TYPES_SUCCESS = "GET_LEAVE_TYPES_SUCCESS"

export const GET_LEAVE_TYPE_DETAIL = "GET_LEAVE_TYPE_DETAIL"
export const GET_LEAVE_TYPE_DETAIL_FAIL = "GET_LEAVE_TYPE_DETAIL_FAIL"
export const GET_LEAVE_TYPE_DETAIL_SUCCESS = "GET_LEAVE_TYPE_DETAIL_SUCCESS"

export const ADD_NEW_LEAVE_TYPE = "ADD_NEW_LEAVE_TYPE"
export const ADD_LEAVE_TYPE_SUCCESS = "ADD_LEAVE_TYPE_SUCCESS"
export const ADD_LEAVE_TYPE_FAIL = "ADD_LEAVE_TYPE_FAIL"

export const UPDATE_LEAVE_TYPE = "UPDATE_LEAVE_TYPE"
export const UPDATE_LEAVE_TYPE_SUCCESS = "UPDATE_LEAVE_TYPE_SUCCESS"
export const UPDATE_LEAVE_TYPE_FAIL = "UPDATE_LEAVE_TYPE_FAIL"

export const DELETE_LEAVE_TYPE = "DELETE_LEAVE_TYPE"
export const DELETE_LEAVE_TYPE_SUCCESS = "DELETE_LEAVE_TYPE_SUCCESS"
export const DELETE_LEAVE_TYPE_FAIL = "DELETE_LEAVE_TYPE_FAIL"
