import {
  GET_BRANCHES,
  GET_BRANCHES_FAIL,
  GET_BRANCHES_SUCCESS,
  GET_BRANCH_DETAIL,
  GET_BRANCH_DETAIL_FAIL,
  GET_BRANCH_DETAIL_SUCCESS,
  ADD_NEW_BRANCH,
  ADD_BRANCH_SUCCESS,
  ADD_BRANCH_FAIL,
  UPDATE_BRANCH,
  UPDATE_BRANCH_SUCCESS,
  UPDATE_BRANCH_FAIL,
  DELETE_BRANCH,
  DELETE_BRANCH_SUCCESS,
  DELETE_BRANCH_FAIL,
} from "./actionTypes"

export const getBranches = () => ({
  type: GET_BRANCHES,
})

export const getBranchesSuccess = branches => ({
  type: GET_BRANCHES_SUCCESS,
  payload: branches,
})

export const getBranchesFail = error => ({
  type: GET_BRANCHES_FAIL,
  payload: error,
})

export const getBranchDetail = branchId => ({
  type: GET_BRANCH_DETAIL,
  branchId,
})

export const getBranchDetailSuccess = branchDetails => ({
  type: GET_BRANCH_DETAIL_SUCCESS,
  payload: branchDetails,
})

export const getBranchDetailFail = error => ({
  type: GET_BRANCH_DETAIL_FAIL,
  payload: error,
})

export const addNewBranch = branch => ({
  type: ADD_NEW_BRANCH,
  payload: branch,
})

export const addBranchSuccess = branch => ({
  type: ADD_BRANCH_SUCCESS,
  payload: branch,
})

export const addBranchFail = error => ({
  type: ADD_BRANCH_FAIL,
  payload: error,
})

export const updateBranch = branch => ({
  type: UPDATE_BRANCH,
  payload: branch,
})

export const updateBranchSuccess = branch => ({
  type: UPDATE_BRANCH_SUCCESS,
  payload: branch,
})

export const updateBranchFail = error => ({
  type: UPDATE_BRANCH_FAIL,
  payload: error,
})

export const deleteBranch = branch => ({
  type: DELETE_BRANCH,
  payload: branch,
})

export const deleteBranchSuccess = branch => ({
  type: DELETE_BRANCH_SUCCESS,
  payload: branch,
})

export const deleteBranchFail = error => ({
  type: DELETE_BRANCH_FAIL,
  payload: error,
})
