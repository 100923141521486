import {
  GET_JOB_TITLES_FAIL,
  GET_JOB_TITLES_SUCCESS,
  GET_JOB_TITLE_DETAIL_FAIL,
  GET_JOB_TITLE_DETAIL_SUCCESS,
  ADD_JOB_TITLE_SUCCESS,
  ADD_JOB_TITLE_FAIL,
  UPDATE_JOB_TITLE_SUCCESS,
  UPDATE_JOB_TITLE_FAIL,
  DELETE_JOB_TITLE_SUCCESS,
  DELETE_JOB_TITLE_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  jobTitles: [],
  jobTitleDetail: {},
  error: {},
}

const JobTitles = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_JOB_TITLES_SUCCESS:
      return {
        ...state,
        error: {},
        jobTitles: action.payload,
      }

    case GET_JOB_TITLES_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_JOB_TITLE_DETAIL_SUCCESS:
      return {
        ...state,
        error: {},
        jobTitleDetail: action.payload,
      }

    case GET_JOB_TITLE_DETAIL_FAIL:
      return {
        ...state,
        jobTitleDetail: {},
        error: action.payload,
      }

    case ADD_JOB_TITLE_SUCCESS:
      return {
        ...state,
        error: {},
        jobTitleDetail: action.payload,
        jobTitles: [...state.jobTitles, action.payload],
      }

    case ADD_JOB_TITLE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case UPDATE_JOB_TITLE_SUCCESS:
      return {
        ...state,
        error: {},
        jobTitleDetail: action.payload,
        jobTitles: state.jobTitles.map(jobTitle =>
          jobTitle.id.toString() === action.payload.id.toString()
            ? { jobTitle, ...action.payload }
            : jobTitle
        ),
      }

    case UPDATE_JOB_TITLE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_JOB_TITLE_SUCCESS:
      return {
        ...state,
        error: {},
        jobTitles: state.jobTitles.filter(
          jobTitle => jobTitle.id.toString() !== action.payload.id.toString()
        ),
      }

    case DELETE_JOB_TITLE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default JobTitles
