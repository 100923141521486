import {
  GET_PAYROLL_EXTRAS,
  GET_PAYROLL_EXTRAS_FAIL,
  GET_PAYROLL_EXTRAS_SUCCESS,
  GET_PAYROLL_EXTRA_DETAIL,
  GET_PAYROLL_EXTRA_DETAIL_FAIL,
  GET_PAYROLL_EXTRA_DETAIL_SUCCESS,
  ADD_NEW_PAYROLL_EXTRA,
  ADD_PAYROLL_EXTRA_SUCCESS,
  ADD_PAYROLL_EXTRA_FAIL,
  UPDATE_PAYROLL_EXTRA,
  UPDATE_PAYROLL_EXTRA_SUCCESS,
  UPDATE_PAYROLL_EXTRA_FAIL,
  DELETE_PAYROLL_EXTRA,
  DELETE_PAYROLL_EXTRA_SUCCESS,
  DELETE_PAYROLL_EXTRA_FAIL,
} from "./actionTypes"

export const getPayrollExtras = () => ({
  type: GET_PAYROLL_EXTRAS,
})

export const getPayrollExtrasSuccess = payrollExtras => ({
  type: GET_PAYROLL_EXTRAS_SUCCESS,
  payload: payrollExtras,
})

export const getPayrollExtrasFail = error => ({
  type: GET_PAYROLL_EXTRAS_FAIL,
  payload: error,
})

export const getPayrollExtraDetail = payrollExtraId => ({
  type: GET_PAYROLL_EXTRA_DETAIL,
  payrollExtraId,
})

export const getPayrollExtraDetailSuccess = payrollExtraDetails => ({
  type: GET_PAYROLL_EXTRA_DETAIL_SUCCESS,
  payload: payrollExtraDetails,
})

export const getPayrollExtraDetailFail = error => ({
  type: GET_PAYROLL_EXTRA_DETAIL_FAIL,
  payload: error,
})

export const addNewPayrollExtra = payrollExtra => ({
  type: ADD_NEW_PAYROLL_EXTRA,
  payload: payrollExtra,
})

export const addPayrollExtraSuccess = payrollExtra => ({
  type: ADD_PAYROLL_EXTRA_SUCCESS,
  payload: payrollExtra,
})

export const addPayrollExtraFail = error => ({
  type: ADD_PAYROLL_EXTRA_FAIL,
  payload: error,
})

export const updatePayrollExtra = payrollExtra => ({
  type: UPDATE_PAYROLL_EXTRA,
  payload: payrollExtra,
})

export const updatePayrollExtraSuccess = payrollExtra => ({
  type: UPDATE_PAYROLL_EXTRA_SUCCESS,
  payload: payrollExtra,
})

export const updatePayrollExtraFail = error => ({
  type: UPDATE_PAYROLL_EXTRA_FAIL,
  payload: error,
})

export const deletePayrollExtra = payrollExtra => ({
  type: DELETE_PAYROLL_EXTRA,
  payload: payrollExtra,
})

export const deletePayrollExtraSuccess = payrollExtra => ({
  type: DELETE_PAYROLL_EXTRA_SUCCESS,
  payload: payrollExtra,
})

export const deletePayrollExtraFail = error => ({
  type: DELETE_PAYROLL_EXTRA_FAIL,
  payload: error,
})
