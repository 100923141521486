import {
  GET_BRANCHES_FAIL,
  GET_BRANCHES_SUCCESS,
  GET_BRANCH_DETAIL_FAIL,
  GET_BRANCH_DETAIL_SUCCESS,
  ADD_BRANCH_SUCCESS,
  ADD_BRANCH_FAIL,
  UPDATE_BRANCH_SUCCESS,
  UPDATE_BRANCH_FAIL,
  DELETE_BRANCH_SUCCESS,
  DELETE_BRANCH_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  branches: [],
  branchDetail: {},
  error: {},
}

const Branches = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_BRANCHES_SUCCESS:
      return {
        ...state,
        error: {},
        branches: action.payload,
      }

    case GET_BRANCHES_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_BRANCH_DETAIL_SUCCESS:
      return {
        ...state,
        error: {},
        branchDetail: action.payload,
      }

    case GET_BRANCH_DETAIL_FAIL:
      return {
        ...state,
        branchDetail: {},
        error: action.payload,
      }

    case ADD_BRANCH_SUCCESS:
      return {
        ...state,
        error: {},
        branchDetail: action.payload,
        branches: [...state.branches, action.payload],
      }

    case ADD_BRANCH_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case UPDATE_BRANCH_SUCCESS:
      return {
        ...state,
        error: {},
        branchDetail: action.payload,
        branches: state.branches.map(branch =>
          branch.id.toString() === action.payload.id.toString()
            ? { branch, ...action.payload }
            : branch
        ),
      }

    case UPDATE_BRANCH_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_BRANCH_SUCCESS:
      return {
        ...state,
        error: {},
        branches: state.branches.filter(
          branch => branch.id.toString() !== action.payload.id.toString()
        ),
      }

    case DELETE_BRANCH_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default Branches
