import React, { useEffect, useState, useRef, useMemo } from "react"
import { withRouter, Link } from "react-router-dom"

import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  UncontrolledTooltip,
  Input,
  Form,
} from "reactstrap"
import ReactSelect from "react-select"
import * as Yup from "yup"
import { useFormik } from "formik"
import { find, get, isEmpty } from "lodash"

//Import Common Components
import Breadcrumbs from "components/Common/Breadcrumb"
import DeleteModal from "components/Common/DeleteModal"
import TableContainer from "components/Common/TableContainer"

//redux
import { useSelector, useDispatch } from "react-redux"

import {
  addNewJobTitle,
  getJobTitles,
  getUsers as onGetUsers,
  updateJobTitle,
} from "store/actions"

const JobTitlesList = props => {
  //meta title
  document.title = "Job Titles | React Admin & Dashboard Template"

  const dispatch = useDispatch()
  const [jobTitle, setJobTitle] = useState()

  const EMPTY_JOB_TITLE = {
    name: jobTitle?.name ?? "",
    min_salary: jobTitle?.min_salary ?? 0,
    max_salary: jobTitle?.max_salary ?? 0,
  }

  const JOB_TITLE_VALIDATION = {
    name: Yup.string().required("Please enter name"),
  }

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: EMPTY_JOB_TITLE,
    validationSchema: Yup.object(JOB_TITLE_VALIDATION),
    onSubmit: values => {
      const tmp = {
        id: jobTitle?.id,
        ...values,
      }
      if (isEdit) {
        // update jobTitle
        dispatch(updateJobTitle(tmp))
        // validation.resetForm()
        // setIsEdit(false)
      } else {
        // save new jobTitle
        dispatch(addNewJobTitle(tmp))
        // validation.resetForm()
      }
      // toggle()
    },
  })

  const { jobTitles } = useSelector(state => ({
    jobTitles: state.JobTitles.jobTitles,
  }))

  const [modal, setModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)

  const columns = useMemo(
    () => [
      {
        Header: "id",
        accessor: "id",
      },
      {
        Header: "Name",
        accessor: "name",
        filterable: true,
      },
      {
        Header: "Minimum Salary",
        accessor: "min_salary",
        filterable: true,
      },
      {
        Header: "Maximum Salary",
        accessor: "max_salary",
        filterable: true,
      },
      {
        Header: "Action",
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  const rowData = cellProps.row.original
                  handleRowClick(rowData)
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const rowData = cellProps.row.original
                  onClickDelete(rowData)
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </Link>
            </div>
          )
        },
      },
    ],
    []
  )

  useEffect(() => {
    dispatch(getJobTitles())
  }, [dispatch])

  const toggle = () => {
    setModal(!modal)
  }

  const handleRowClick = arg => {
    const jobTitle = {
      ...arg,
    }

    setJobTitle(jobTitle)
    setIsEdit(true)

    toggle()
  }

  var node = useRef()
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page)
    }
  }

  //delete customer
  const [deleteModal, setDeleteModal] = useState(false)

  const onClickDelete = jobTitle => {
    setJobTitle(jobTitle)
    setDeleteModal(true)
  }

  const handleDeleteUser = () => {
    dispatch(onDeleteUser(jobTitle.id))
    onPaginationPageChange(1)
    setDeleteModal(false)
  }

  const handleAddClick = () => {
    setIsEdit(false)
    toggle()
  }

  const keyField = "id"

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Job Titles" breadcrumbItem="Job Titles List" />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={jobTitles}
                    isGlobalFilter={true}
                    isAddOptions="JobTitle"
                    handleAddOptionClick={handleAddClick}
                    customPageSize={10}
                    className="custom-header-css"
                  />

                  <Modal isOpen={modal} toggle={toggle}>
                    <ModalHeader toggle={toggle} tag="h4">
                      {!!isEdit ? "Edit JobTitle" : "Add JobTitle"}
                    </ModalHeader>
                    <ModalBody>
                      <Form
                        onSubmit={e => {
                          e.preventDefault()
                          validation.handleSubmit()
                          return false
                        }}
                      >
                        <Row>
                          <Col xs={12}>
                            <div className="mb-3">
                              <Label className="form-label">Name</Label>
                              <Input
                                name="name"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.name || ""}
                                invalid={
                                  validation.touched.name &&
                                  validation.errors.name
                                    ? true
                                    : false
                                }
                              />
                              <FormFeedback type="invalid">
                                {validation.errors.name}
                              </FormFeedback>
                            </div>
                          </Col>
                          <Col md={6}>
                            <div className="mb-3">
                              <Label className="form-label">
                                Minimum Salary
                              </Label>
                              <Input
                                name="min_salary"
                                type="number"
                                min="0"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.min_salary || ""}
                                invalid={
                                  validation.touched.min_salary &&
                                  validation.errors.min_salary
                                    ? true
                                    : false
                                }
                              />
                              <FormFeedback type="invalid">
                                {validation.errors.min_salary}
                              </FormFeedback>
                            </div>
                          </Col>
                          <Col md={6}>
                            <div className="mb-3">
                              <Label className="form-label">
                                Maximum Salary
                              </Label>
                              <Input
                                name="max_salary"
                                type="number"
                                min="0"
                                max={validation.values.max_salary}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.max_salary || ""}
                                invalid={
                                  validation.touched.max_salary &&
                                  validation.errors.max_salary
                                    ? true
                                    : false
                                }
                              />
                              <FormFeedback type="invalid">
                                {validation.errors.max_salary}
                              </FormFeedback>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <div className="text-end">
                              <button type="submit" className="btn btn-success">
                                Save
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </ModalBody>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(JobTitlesList)
