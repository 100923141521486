import {
  GET_DEPARTMENTS_FAIL,
  GET_DEPARTMENTS_SUCCESS,
  GET_DEPARTMENT_DETAIL_FAIL,
  GET_DEPARTMENT_DETAIL_SUCCESS,
  ADD_DEPARTMENT_SUCCESS,
  ADD_DEPARTMENT_FAIL,
  UPDATE_DEPARTMENT_SUCCESS,
  UPDATE_DEPARTMENT_FAIL,
  DELETE_DEPARTMENT_SUCCESS,
  DELETE_DEPARTMENT_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  departments: [],
  departmentDetail: {},
  error: {},
}

const Departments = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_DEPARTMENTS_SUCCESS:
      return {
        ...state,
        error: {},
        departments: action.payload,
      }

    case GET_DEPARTMENTS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_DEPARTMENT_DETAIL_SUCCESS:
      return {
        ...state,
        error: {},
        departmentDetail: action.payload,
      }

    case GET_DEPARTMENT_DETAIL_FAIL:
      return {
        ...state,
        departmentDetail: {},
        error: action.payload,
      }

    case ADD_DEPARTMENT_SUCCESS:
      return {
        ...state,
        error: {},
        departmentDetail: action.payload,
        departments: [...state.departments, action.payload],
      }

    case ADD_DEPARTMENT_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case UPDATE_DEPARTMENT_SUCCESS:
      return {
        ...state,
        error: {},
        departmentDetail: action.payload,
        departments: state.departments.map(department =>
          department.id.toString() === action.payload.id.toString()
            ? { department, ...action.payload }
            : department
        ),
      }

    case UPDATE_DEPARTMENT_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_DEPARTMENT_SUCCESS:
      return {
        ...state,
        error: {},
        departments: state.departments.filter(
          department => department.id.toString() !== action.payload.id.toString()
        ),
      }

    case DELETE_DEPARTMENT_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default Departments
