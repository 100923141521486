import { call, put, takeEvery } from "redux-saga/effects"

// PayrollExtra Redux States
import {
  GET_PAYROLL_EXTRAS,
  GET_PAYROLL_EXTRA_DETAIL,
  ADD_NEW_PAYROLL_EXTRA,
  DELETE_PAYROLL_EXTRA,
  UPDATE_PAYROLL_EXTRA,
} from "./actionTypes"
import {
  getPayrollExtrasSuccess,
  getPayrollExtrasFail,
  getPayrollExtraDetailSuccess,
  getPayrollExtraDetailFail,
  addPayrollExtraFail,
  addPayrollExtraSuccess,
  updatePayrollExtraSuccess,
  updatePayrollExtraFail,
  deletePayrollExtraSuccess,
  deletePayrollExtraFail,
} from "./actions"

//Include Both Helper File with needed methods
import { PayrollExtraApi } from "helpers/Api/payroll-extras"
import { apiError, apiLoaded, apiLoading } from "store/actions"

function* fetchPayrollExtra() {
  try {
    yield put(apiLoading())
    const response = yield call(PayrollExtraApi.all)
    if (response.success) {
      yield put(apiLoaded())
      yield put(getPayrollExtrasSuccess(response.data))
    } else {
      yield put(apiError(response.message))
      yield put(getPayrollExtrasFail(response.message))
    }
  } catch (error) {
    yield put(apiError(error.response?.data))
    yield put(getPayrollExtrasFail(error))
  }
}

function* fetchPayrollExtraDetail({ payrollExtraId }) {
  try {
    yield put(apiLoading())
    if (payrollExtraId === undefined) {
      yield put(getPayrollExtraDetailSuccess(null))
    } else {
      const response = yield call(PayrollExtraApi.show, payrollExtraId)
      if (response.success) {
        yield put(apiLoaded())
        yield put(getPayrollExtraDetailSuccess(response.data))
      } else {
        yield put(apiError(response.message))
        yield put(getPayrollExtraDetailFail(response.message))
      }
    }
  } catch (error) {
    yield put(apiError(error.response?.data))
    yield put(getPayrollExtraDetailFail(error))
  }
}

function* onUpdatePayrollExtra({ payload: payrollExtra }) {
  try {
    yield put(apiLoading())
    const response = yield call(PayrollExtraApi.update, payrollExtra)
    if (response.success) {
      yield put(apiLoaded())
      yield put(updatePayrollExtraSuccess(response.data))
    } else {
      yield put(apiError(response.message))
      yield put(updatePayrollExtraFail(response.message))
    }
  } catch (error) {
    if (error.response) {
      yield put(apiError(error.response.message))
      yield put(updatePayrollExtraFail(error.response.data))
    } else {
      yield put(updatePayrollExtraFail(error))
      yield put(apiError(error.response?.data))
    }
  }
}

function* onDeletePayrollExtra({ payload: payrollExtra }) {
  try {
    yield put(apiLoading())
    const response = yield call(PayrollExtraApi.destroy, payrollExtra)
    if (response.success) {
      yield put(apiLoaded())
      yield put(deletePayrollExtraSuccess(response.data))
    } else {
      yield put(apiError(response.message))
      yield put(deletePayrollExtraFail(response.message))
    }
  } catch (error) {
    yield put(apiError(error.response?.data))
    yield put(deletePayrollExtraFail(error))
  }
}

function* onAddNewPayrollExtra({ payload: payrollExtra }) {
  try {
    yield put(apiLoading())
    const response = yield call(PayrollExtraApi.create, payrollExtra)
    if (response.success) {
      yield put(apiLoaded())
      yield put(addPayrollExtraSuccess(response.data))
    } else {
      yield put(apiError(response.message))
      yield put(addPayrollExtraFail(response.message))
    }
  } catch (error) {
    if (error.response) {
      yield put(addPayrollExtraFail(error.response.data))
      yield put(apiError(error.response.message))
    } else {
      yield put(addPayrollExtraFail(error))
      yield put(apiError(error.response?.data))
    }
  }
}

function* payrollExtrasSaga() {
  yield takeEvery(GET_PAYROLL_EXTRAS, fetchPayrollExtra)
  yield takeEvery(GET_PAYROLL_EXTRA_DETAIL, fetchPayrollExtraDetail)
  yield takeEvery(ADD_NEW_PAYROLL_EXTRA, onAddNewPayrollExtra)
  yield takeEvery(UPDATE_PAYROLL_EXTRA, onUpdatePayrollExtra)
  yield takeEvery(DELETE_PAYROLL_EXTRA, onDeletePayrollExtra)
}

export default payrollExtrasSaga
