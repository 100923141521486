import { call, put, takeEvery } from "redux-saga/effects"

// Company Redux States
import {
  GET_COMPANIES,
  GET_COMPANY_DETAIL,
  ADD_NEW_COMPANY,
  DELETE_COMPANY,
  UPDATE_COMPANY,
} from "./actionTypes"
import {
  getCompaniesSuccess,
  getCompaniesFail,
  getCompanyDetailSuccess,
  getCompanyDetailFail,
  addCompanyFail,
  addCompanySuccess,
  updateCompanySuccess,
  updateCompanyFail,
  deleteCompanySuccess,
  deleteCompanyFail,
} from "./actions"

//Include Both Helper File with needed methods
import { CompanyApi } from "helpers/Api/companies"
import { apiError, apiLoaded, apiLoading } from "store/actions"

function* fetchCompanies() {
  try {
    yield put(apiLoading())
    const response = yield call(CompanyApi.all)
    if (response.success) {
      yield put(apiLoaded())
      yield put(getCompaniesSuccess(response.data))
    } else {
      yield put(apiError(response.message))
      yield put(getCompaniesFail(response.message))
    }
  } catch (error) {
    yield put(apiError(error.response?.data))
    yield put(getCompaniesFail(error))
  }
}

function* fetchCompanyDetail({ companyId }) {
  try {
    yield put(apiLoading())
    if (companyId === undefined) {
      yield put(getCompanyDetailSuccess(null))
    } else {
      const response = yield call(CompanyApi.show, companyId)
      if (response.success) {
        yield put(apiLoaded())
        yield put(getCompanyDetailSuccess(response.data))
      } else {
        yield put(apiError(response.message))
        yield put(getCompanyDetailFail(response.message))
      }
    }
  } catch (error) {
    yield put(apiError(error.response?.data))
    yield put(getCompanyDetailFail(error))
  }
}

function* onUpdateCompany({ payload: company }) {
  try {
    yield put(apiLoading())
    const response = yield call(CompanyApi.update, company)
    if (response.success) {
      yield put(apiLoaded())
      yield put(updateCompanySuccess(response.data))
    } else {
      yield put(apiError(response.message))
      yield put(updateCompanyFail(response.message))
    }
  } catch (error) {
    if (error.response) {
      yield put(apiError(error.response.message))
      yield put(updateCompanyFail(error.response.data))
    } else {
      yield put(updateCompanyFail(error))
      yield put(apiError(error.response?.data))
    }
  }
}

function* onDeleteCompany({ payload: company }) {
  try {
    yield put(apiLoading())
    const response = yield call(CompanyApi.destroy, company)
    if (response.success) {
      yield put(apiLoaded())
      yield put(deleteCompanySuccess(response.data))
    } else {
      yield put(apiError(response.message))
      yield put(deleteCompanyFail(response.message))
    }
  } catch (error) {
    yield put(apiError(error.response?.data))
    yield put(deleteCompanyFail(error))
  }
}

function* onAddNewCompany({ payload: company }) {
  try {
    yield put(apiLoading())
    const response = yield call(CompanyApi.create, company)
    if (response.success) {
      yield put(apiLoaded())
      yield put(addCompanySuccess(response.data))
    } else {
      yield put(apiError(response.message))
      yield put(addCompanyFail(response.message))
    }
  } catch (error) {
    if (error.response) {
      yield put(addCompanyFail(error.response.data))
      yield put(apiError(error.response.message))
    } else {
      yield put(addCompanyFail(error))
      yield put(apiError(error.response?.data))
    }
  }
}

function* companiesSaga() {
  yield takeEvery(GET_COMPANIES, fetchCompanies)
  yield takeEvery(GET_COMPANY_DETAIL, fetchCompanyDetail)
  yield takeEvery(ADD_NEW_COMPANY, onAddNewCompany)
  yield takeEvery(UPDATE_COMPANY, onUpdateCompany)
  yield takeEvery(DELETE_COMPANY, onDeleteCompany)
}

export default companiesSaga
