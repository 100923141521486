export const GET_PAYROLL_EXTRAS = "GET_PAYROLL_EXTRAS"
export const GET_PAYROLL_EXTRAS_FAIL = "GET_PAYROLL_EXTRAS_FAIL"
export const GET_PAYROLL_EXTRAS_SUCCESS = "GET_PAYROLL_EXTRAS_SUCCESS"

export const GET_PAYROLL_EXTRA_DETAIL = "GET_PAYROLL_EXTRA_DETAIL"
export const GET_PAYROLL_EXTRA_DETAIL_FAIL = "GET_PAYROLL_EXTRA_DETAIL_FAIL"
export const GET_PAYROLL_EXTRA_DETAIL_SUCCESS =
  "GET_PAYROLL_EXTRA_DETAIL_SUCCESS"

export const ADD_NEW_PAYROLL_EXTRA = "ADD_NEW_PAYROLL_EXTRA"
export const ADD_PAYROLL_EXTRA_SUCCESS = "ADD_PAYROLL_EXTRA_SUCCESS"
export const ADD_PAYROLL_EXTRA_FAIL = "ADD_PAYROLL_EXTRA_FAIL"

export const UPDATE_PAYROLL_EXTRA = "UPDATE_PAYROLL_EXTRA"
export const UPDATE_PAYROLL_EXTRA_SUCCESS = "UPDATE_PAYROLL_EXTRA_SUCCESS"
export const UPDATE_PAYROLL_EXTRA_FAIL = "UPDATE_PAYROLL_EXTRA_FAIL"

export const DELETE_PAYROLL_EXTRA = "DELETE_PAYROLL_EXTRA"
export const DELETE_PAYROLL_EXTRA_SUCCESS = "DELETE_PAYROLL_EXTRA_SUCCESS"
export const DELETE_PAYROLL_EXTRA_FAIL = "DELETE_PAYROLL_EXTRA_FAIL"
