import { call, put, takeEvery } from "redux-saga/effects"

// Department Redux States
import {
  GET_DEPARTMENTS,
  GET_DEPARTMENT_DETAIL,
  ADD_NEW_DEPARTMENT,
  DELETE_DEPARTMENT,
  UPDATE_DEPARTMENT,
} from "./actionTypes"
import {
  getDepartmentsSuccess,
  getDepartmentsFail,
  getDepartmentDetailSuccess,
  getDepartmentDetailFail,
  addDepartmentFail,
  addDepartmentSuccess,
  updateDepartmentSuccess,
  updateDepartmentFail,
  deleteDepartmentSuccess,
  deleteDepartmentFail,
} from "./actions"

//Include Both Helper File with needed methods
import { DepartmentApi } from "helpers/Api/departments"
import { apiError, apiLoaded, apiLoading } from "store/actions"

function* fetchDepartments() {
  try {
    yield put(apiLoading())
    const response = yield call(DepartmentApi.all)
    if (response.success) {
      yield put(apiLoaded())
      yield put(getDepartmentsSuccess(response.data))
    } else {
      yield put(apiError(response.message))
      yield put(getDepartmentsFail(response.message))
    }
  } catch (error) {
    yield put(apiError(error.response?.data))
    yield put(getDepartmentsFail(error))
  }
}

function* fetchDepartmentDetail({ departmentId }) {
  try {
    yield put(apiLoading())
    if (departmentId === undefined) {
      yield put(getDepartmentDetailSuccess(null))
    } else {
      const response = yield call(DepartmentApi.show, departmentId)
      if (response.success) {
        yield put(apiLoaded())
        yield put(getDepartmentDetailSuccess(response.data))
      } else {
        yield put(apiError(response.message))
        yield put(getDepartmentDetailFail(response.message))
      }
    }
  } catch (error) {
    yield put(apiError(error.response?.data))
    yield put(getDepartmentDetailFail(error))
  }
}

function* onUpdateDepartment({ payload: department }) {
  try {
    yield put(apiLoading())
    const response = yield call(DepartmentApi.update, department)
    if (response.success) {
      yield put(apiLoaded())
      yield put(updateDepartmentSuccess(response.data))
    } else {
      yield put(apiError(response.message))
      yield put(updateDepartmentFail(response.message))
    }
  } catch (error) {
    if (error.response) {
      yield put(apiError(error.response.message))
      yield put(updateDepartmentFail(error.response.data))
    } else {
      yield put(updateDepartmentFail(error))
      yield put(apiError(error.response?.data))
    }
  }
}

function* onDeleteDepartment({ payload: department }) {
  try {
    yield put(apiLoading())
    const response = yield call(DepartmentApi.destroy, department)
    if (response.success) {
      yield put(apiLoaded())
      yield put(deleteDepartmentSuccess(response.data))
    } else {
      yield put(apiError(response.message))
      yield put(deleteDepartmentFail(response.message))
    }
  } catch (error) {
    yield put(apiError(error.response?.data))
    yield put(deleteDepartmentFail(error))
  }
}

function* onAddNewDepartment({ payload: department }) {
  try {
    yield put(apiLoading())
    const response = yield call(DepartmentApi.create, department)
    if (response.success) {
      yield put(apiLoaded())
      yield put(addDepartmentSuccess(response.data))
    } else {
      yield put(apiError(response.message))
      yield put(addDepartmentFail(response.message))
    }
  } catch (error) {
    if (error.response) {
      yield put(addDepartmentFail(error.response.data))
      yield put(apiError(error.response.message))
    } else {
      yield put(addDepartmentFail(error))
      yield put(apiError(error.response?.data))
    }
  }
}

function* departmentsSaga() {
  yield takeEvery(GET_DEPARTMENTS, fetchDepartments)
  yield takeEvery(GET_DEPARTMENT_DETAIL, fetchDepartmentDetail)
  yield takeEvery(ADD_NEW_DEPARTMENT, onAddNewDepartment)
  yield takeEvery(UPDATE_DEPARTMENT, onUpdateDepartment)
  yield takeEvery(DELETE_DEPARTMENT, onDeleteDepartment)
}

export default departmentsSaga
