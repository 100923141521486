import { API_LOADING, API_LOADED, API_ERROR } from "./actionTypes"

const initialState = {
  error: {},
  loading: false,
}

const apiGlobal = (state = initialState, action) => {
  let tmp = {
    ...state,
  }
  switch (action.type) {
    case API_LOADING:
      tmp.error = {}
      tmp.loading = true
      break
    case API_ERROR:
      tmp.error = action.payload
      tmp.loading = false
      break
    case API_LOADED:
      tmp.error = {}
      tmp.loading = false
      break
    default:
      break
  }
  return tmp
}

export default apiGlobal
