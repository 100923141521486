import { API_LOADING, API_LOADED, API_ERROR } from "./actionTypes"

export const apiLoading = payload => {
  return {
    type: API_LOADING,
    payload,
  }
}

export const apiLoaded = payload => {
  return {
    type: API_LOADED,
    payload,
  }
}

export const apiError = payload => {
  return {
    type: API_ERROR,
    payload,
  }
}
